import { Box } from '@mui/material';
import { Modal } from 'antd';
import { MainButton, Text, Title } from 'shared/ui';
import { IConfirmModal } from '../interfaces';
import {
  button,
  inner,
  modal,
  modalChildren,
  row,
  table,
  tableBodyCell,
  tableHeaderCell,
  tableInner,
  tableTitle,
  tableWrapper,
  title,
  wrapper,
} from '../styles';

export const ConfirmTransaction = (props: IConfirmModal) => {
  const {
    isOpened,
    closeHandler,
    data,
  } = props;

  return (
    <Modal
      open={isOpened}
      footer={null}
      style={modal}
      styles={modalChildren}
      closable
      destroyOnClose
      onCancel={closeHandler}
      width='100%'
      centered={true}
    >
      <Box sx={wrapper}>
        <Title styles={title}>Confirm your transaction</Title>

        <Box sx={inner}>
          {data.map((tableItem) => (
            <Box
              key={tableItem.title}
              sx={tableWrapper}
            >
              <Title styles={tableTitle}>
                {tableItem.title}
              </Title>

              <Box sx={tableInner}>
                <Box sx={table}>
                  <Box sx={row}>
                    {tableItem.columns.map((column) => (
                      <Text
                        key={column}
                        styles={tableHeaderCell}
                        type='secondary'
                      >
                        {column}
                      </Text>
                    ))}
                  </Box>

                  {tableItem.items.map((rowItems, rowIndex) => (
                    <Box
                      key={rowIndex + rowItems.length}
                      sx={row}
                    >
                      {rowItems.map((rowItem) => (
                        <Text
                          key={rowItem.value}
                          styles={tableBodyCell}
                          type={rowItem.type}
                        >
                          {rowItem.value}
                        </Text>
                      ))}
                    </Box>
                  ))}
                </Box>
              </Box>
            </Box>
          ))}
        </Box>

        <MainButton
          type='primary'
          size='large'
          styles={button}
        >
          Confirm
        </MainButton>
      </Box>
    </Modal>
  );
};
