import { Box } from '@mui/material';
import { exchangeActions } from 'entities/exchange/model/slices/exchange-slice';
import { ExchangeAccount } from 'entities/exchange/model/types/exchange-account';
import { CreateExchangeProps } from 'entities/exchange/model/types/exchange-connect-update.types';
import { getUserSkyrexUuid } from 'entities/user/model/selectors/get-user-skyrex-uuid/get-user-skyrex-uuid';
import { connectExchange } from 'features/connect-exchange/api/connect-exchange';
import { useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { getExchanges, getExchangesIds } from 'widgets/exchanges/api/get-exchanges';
import { ActionBlock, Form, Info } from 'widgets/exchanges/components';
import { generateRandomHash } from 'widgets/exchanges/helpers/generate-hash-oauth-link';
import { getWrapperStyles, logo, okxLogo } from 'widgets/exchanges/styles';
import { OkxLogo, Verify } from 'shared/icons';
import { Loader, Title } from 'shared/ui';
import {
  ConnectedExchangeAccountStatus as ExchangeUpdatedModalStatus,
  TwoFactorFailed as ErrorConnectModal,
} from 'shared/ui/modals';

export const CreateOkx = (props: CreateExchangeProps) => {
  const {
    isModalView,
    returnToAllExchanges,
  } = props;

  const uuid = useSelector(getUserSkyrexUuid);

  const [isApiKeysConnection, setIsApiKeysConnection] = useState<boolean>(false);
  const [exchangeTitle, setExchangeTitle] = useState<string>('');
  const [apiKey, setApiKey] = useState<string>('');
  const [passphrase, setPassphrase] = useState<string>('');
  const [apiSecret, setApiSecret] = useState<string>('');
  const [modalOpen, setIsModalOpen] = useState<boolean>(false);
  const [isLoading, setIsLoading] = useState<boolean>(false);
  const [connectExchangeError, setExchangeConnectError] = useState<boolean>(false);

  const handleCloseConnectErrorModal = () => setExchangeConnectError(false);
  
  const handleCloseModal = () => {
    setIsModalOpen(false);
  };
  
  const dispatch = useDispatch();

  const connectOkxWithApiKeys = async () => {
    setIsLoading(true);

    try {
      const dataForConnectOauth = {
        uuid,
        exchangeTitle,
        exchangeName: 'okx',
        apiKey,
        apiSecret,
        passphrase,
      };

      const okxConnectResponse = await connectExchange(dataForConnectOauth);

      if (okxConnectResponse.success) {
        const exchangesIdsResponse = await getExchangesIds(uuid);
        if (exchangesIdsResponse.success) {
          const {
            accounts,
          } = exchangesIdsResponse.data;
          const accountsIds = accounts.map((account: ExchangeAccount) => account.exchangeAccountUuid);

          const allAccountsData = await getExchanges(accountsIds);
          dispatch(exchangeActions.setAllExchanges(allAccountsData.data.accounts));
          
          setIsLoading(false);
          setIsModalOpen(true);

          return;
        }

        setExchangeConnectError(true);
        setIsModalOpen(true);
      }

      setExchangeConnectError(true);
      setIsModalOpen(true);
    } catch (error) {
      setExchangeConnectError(true);
      setIsModalOpen(true);
    } finally {
      setIsLoading(false);
    }
  };

  const handleChangeTitle = (value: string) => {
    setExchangeTitle(value);
  };

  const handleChangeApiKey = (value: string) => {
    setApiKey(value);
  };

  const handleChangeApiSecret = (value: string) => {
    setApiSecret(value);
  };

  const handleChangePassphrase = (value: string) => {
    setPassphrase(value);
  };

  const connectOkx = () => {
    const randomHash = generateRandomHash(16);
    const okxUrl = `https://www.okx.com/account/oauth?access_type=offline&client_id=5456c77cf4d24df885ecc2ab88b90f268XsZQIsq&redirect_uri=https%3A%2F%2Fapp.skyrexio.com%2Foauth%2Fcreate%2Fokx&response_type=code&scope=fast_api&state=${randomHash}`;
    
    window.open(okxUrl, '_self');
  };

  const switchForm = () => {
    setIsApiKeysConnection((prev) => !prev);
  };

  const getContent = () => {
    if (isApiKeysConnection) {
      return (
        <Form
          returnToAllExchanges={returnToAllExchanges}
          type='okx'
          fields={[{
            label: 'Enter any name',
            value: exchangeTitle,
            placeholder: 'Name',
            onChange: handleChangeTitle,
          }, {
            label: 'Paste your API key',
            value: apiKey,
            placeholder: 'Key',
            autoComplete: 'off',
            onChange: handleChangeApiKey,
          }, {
            label: 'Paste your API secret',
            value: apiSecret,
            mask: true,
            placeholder: 'Secret',
            onChange: handleChangeApiSecret,
          }, {
            label: 'Passphrase',
            value: passphrase,
            placeholder: 'Input here',
            onChange: handleChangePassphrase,
          }]}
          actionBlock={(
            <ActionBlock
              button={{
                label: 'Connect OKX',
                action: connectOkxWithApiKeys,
              }}
              link={{
                label: (
                  <Box
                    display='flex'
                    alignItems='center'
                    gap={1}
                  >
                    Connect with OAuth
                    {Verify}
                  </Box>
                ),
                action: switchForm,
              }}
              createAccount={{
                label: 'Create OKX account',
                link: 'https://www.okx.com/join/82800770',
              }}
            />
          )}
        />
      );
    }

    return (
      <Info
        returnToAllExchanges={returnToAllExchanges}
        steps={[
          'Click on Connect OKX button',
          'You will be redirected to OKX Exchange',
          'Confirm your connection to Skyrex',
        ]}
        actionBlock={(
          <ActionBlock
            button={{
              label: 'Connect OKX',
              action: connectOkx,
            }}
            link={{
              label: 'Connect with API keys',
              action: switchForm,
            }}
            createAccount={{
              label: 'Create OKX account',
              link: 'https://www.okx.com/join/82800770',
            }}
          />
        )}
      />
    );
  };

  return (
    <>
      <ExchangeUpdatedModalStatus
        isOpen={modalOpen}
        onClose={handleCloseModal}
        title={'Exchange account connected successfully'}
        description={'Now you are in one click to start trading bot or create manual trade using your account'}
        action={handleCloseModal}
      />
      
      <ErrorConnectModal
        isOpen={connectExchangeError}
        title='Failed to connect account'
        description='Check API keys settings are correct and try again'
        closeHandler={handleCloseConnectErrorModal}
      />

      <Box position='relative'>
        {isLoading && (
          <Loader isContentOverflow={true} />
        )}

        <Box sx={getWrapperStyles(!!isModalView)}>
          <Box sx={logo}>
            <Title>Connect</Title>

            <Box sx={okxLogo}>
              {OkxLogo}
            </Box>

            <Title>exchange</Title>
          </Box>

          {getContent()}
        </Box>

      </Box>
    </>
  );
};
