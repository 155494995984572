import { Box } from '@mui/material';
import { Dropdown } from 'antd';
import { CloseGold, Delete, EditIcon, Founds, More, Share, Update } from 'shared/icons';
import { Text } from 'shared/ui';
import {
  actionsWrapper,
  dateWrapper,
  progressWrapper,
  returnWrapper,
  text,
  volumeWrapper,
  wrapper,
} from '../styles';

interface LabelProps {
  baseSymbol: string;
  quoteSymbol: string;
}

export const Label = ({
  baseSymbol, quoteSymbol, 
}: LabelProps) => {
  return (
    <Box sx={wrapper}>
      <Box display='flex'>
        <Text styles={text}>
          {baseSymbol}
        </Text>

        <Text
          type='success'
          styles={text}
        >
          {quoteSymbol}
        </Text>
      </Box>
      
      <Box sx={dateWrapper}>
        <Text
          type='secondary'
          styles={text}
        >
          05.31.2021
        </Text>

        <Text
          type='secondary'
          styles={text}
        >
          12:08:03 PM
        </Text>
      </Box>

      <Box sx={volumeWrapper}>
        <Text
          type='secondary'
          styles={text}
        >
          1886.6 MATIC
        </Text>

        <Text
          type='secondary'
          styles={text}
        >
          3532.45195 USDT
        </Text>
      </Box>

      <Box sx={progressWrapper}>
        progress
      </Box>

      <Box sx={returnWrapper}>
        <Text
          type='success'
          styles={text}
        >
          + $1.840
        </Text>

        <Text
          type='success'
          styles={text}
        >
          + 8.12 %
        </Text>
      </Box>

      <Box sx={actionsWrapper}>
        <Dropdown
          menu={{
            items: [{
              key: 1,
              label: 'Add funds',
              icon: Founds,
            }, {
              key: 2,
              label: 'Close by Market',
              icon: CloseGold,
            }, {
              key: 3,
              label: 'Close by Limit',
              icon: CloseGold,
            }, {
              key: 4,
              label: 'Edit',
              icon: EditIcon,
            }, {
              key: 5,
              label: 'Share',
              icon: Share,
            }, {
              key: 6,
              label: 'Cancel',
              icon: Delete,
            }],
          }}
        >
          <Box>
            <div>
              {More}
            </div>
          </Box>
        </Dropdown>

        <div>
          {Update}
        </div>
      </Box>
    </Box>
  );
};
