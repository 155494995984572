import { Box } from '@mui/material';
import { TableColumnsType } from 'antd';
import { stringSorter } from 'shared/helpers/sorters';
import { Copy } from 'shared/icons';
import { SortIcon, Text } from 'shared/ui';

export const columnsBtc: TableColumnsType = [{
  title: 'Amount',
  dataIndex: 'amount',
  key: 'amount',
  sorter: (a, b) => stringSorter(a.symbol, b.symbol),
  width: 190,
  render: (value: string) => (
    <Text>
      {value}
    </Text>
  ),
  sortIcon: (props) => (
    <SortIcon sortOrder={props.sortOrder} />
  ),
  showSorterTooltip: false,
}, {
  title: 'Transaction',
  dataIndex: 'transaction',
  key: 'transaction',
  width: 190,
  render: (value: string) => (
    <Box
      display='flex'
      alignItems='center'
      gap={1}
    >
      <Text>
        {value}
      </Text>

      <div>
        {Copy}
      </div>
    </Box>
  ),
  showSorterTooltip: false,
}, {
  title: 'Wallet',
  dataIndex: 'wallet',
  key: 'wallet',
  width: 190,
  render: (value: string) => (
    <Box
      display='flex'
      alignItems='center'
      gap={1}
    >
      <Text>
        {value}
      </Text>

      <div>
        {Copy}
      </div>
    </Box>
  ),
  showSorterTooltip: false,
}, {
  title: 'Created date',
  dataIndex: 'createDate',
  key: 'createDate',
  sorter: (a, b) => stringSorter(a.symbol, b.symbol),
  width: 190,
  render: (value: string) => (
    <Text>
      {value}
    </Text>
  ),
  sortIcon: (props) => (
    <SortIcon sortOrder={props.sortOrder} />
  ),
  showSorterTooltip: false,
}, {
  title: 'Paid date',
  dataIndex: 'paidDate',
  key: 'paidDate',
  sorter: (a, b) => stringSorter(a.symbol, b.symbol),
  width: 190,
  render: (value: string) => (
    <Text>
      {value}
    </Text>
  ),
  sortIcon: (props) => (
    <SortIcon sortOrder={props.sortOrder} />
  ),
  showSorterTooltip: false,
}, {
  title: 'Status',
  dataIndex: 'status',
  key: 'status',
  sorter: (a, b) => stringSorter(a.symbol, b.symbol),
  width: 190,
  render: (value: string) => (
    <Text>
      {value}
    </Text>
  ),
  sortIcon: (props) => (
    <SortIcon sortOrder={props.sortOrder} />
  ),
  showSorterTooltip: false,
}];
