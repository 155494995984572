import { Box } from '@mui/material';
import { exchangeActions } from 'entities/exchange/model/slices/exchange-slice';
import { ExchangeAccount } from 'entities/exchange/model/types/exchange-account';
import { CreateExchangeProps } from 'entities/exchange/model/types/exchange-connect-update.types';
import { connectExchange } from 'features/connect-exchange/api/connect-exchange';
import { useState } from 'react';
import { useDispatch } from 'react-redux';
import {
  getExchanges,
  getExchangesIds,
} from 'widgets/exchanges/api/get-exchanges';
import { ActionBlock, Form, Info } from 'widgets/exchanges/components';
import { generateRandomHash } from 'widgets/exchanges/helpers/generate-hash-oauth-link';
import { bybitLogo, getWrapperStyles, logo } from 'widgets/exchanges/styles';
import { getSkyrexUuid } from 'shared/helpers/storage-helper';
import { BybitLogo, Verify } from 'shared/icons';
import { Loader, Title } from 'shared/ui';
import {
  ConnectedExchangeAccountStatus as ExchangeUpdatedModalStatus,
  TwoFactorFailed as ErrorConnectModal,
} from 'shared/ui/modals';

export const CreateBybit = (props: CreateExchangeProps) => {
  const {
    isModalView,
    returnToAllExchanges,
  } = props;
  const skyrexUserUuid = getSkyrexUuid();
  const dispatch = useDispatch();

  const [isApiKeysConnection, setIsApiKeysConnection] = useState<boolean>(false);
  const [exchangeTitle, setExchangeTitle] = useState<string>('');
  const [apiKey, setApiKey] = useState<string>('');
  const [apiSecret, setApiSecret] = useState<string>('');
  const [modalOpen, setIsModalOpen] = useState<boolean>(false);
  const [isLoading, setIsLoading] = useState<boolean>(false);
  const [connectExchangeError, setExchangeConnectError] = useState<boolean>(false);


  const handleCloseModal = () => {
    setIsModalOpen(false);
  };
  
  const handleCloseConnectErrorModal = () => setExchangeConnectError(false);
  
  const connectBybitWithApiKeys = async () => {
    setIsLoading(true);

    try {
      const dataForConnectOauth = {
        uuid: skyrexUserUuid ?? '',
        exchangeTitle,
        exchangeName: 'bybit',
        apiKey,
        apiSecret,
      };

      const bybitConnectResponse = await connectExchange(dataForConnectOauth);

      if (bybitConnectResponse.success) {
        const exchangesIdsResponse = await getExchangesIds(skyrexUserUuid ?? '');
        if (exchangesIdsResponse.success) {
          const {
            accounts,
          } = exchangesIdsResponse.data;
          const accountsIds = accounts.map(
            (account: ExchangeAccount) => account.exchangeAccountUuid,
          );

          const allAccountsData = await getExchanges(accountsIds);
          dispatch(exchangeActions.setAllExchanges(allAccountsData.data.accounts));
          
          setIsLoading(false);
          setIsModalOpen(true);

          return;
        }

        setExchangeConnectError(true);
        setIsModalOpen(true);
      }

      setExchangeConnectError(true);
      setIsModalOpen(true);
    } catch (error) {
      setExchangeConnectError(true);
      setIsModalOpen(true);
    } finally {
      setIsLoading(false);
    }
  };

  const handleChangeTitle = (value: string) => {
    setExchangeTitle(value);
  };

  const handleChangeApiKey = (value: string) => {
    setApiKey(value);
  };

  const handleChangeApiSecret = (value: string) => {
    setApiSecret(value);
  };

  const connectBybit = () => {
    const randomHash = generateRandomHash(48);
    
    const bybitUrl = `https://www.bybit.com/en/oauth?client_id=c1d92cd6aab75&redirect_uri=https%3A%2F%2Fapp.skyrexio.com%2Foauth%2Fcreate%2Fbybit&response_type=code&scope=openapi&state=${randomHash}`;
    window.open(bybitUrl, '_self');
  };

  const switchForm = () => {
    setIsApiKeysConnection((prev) => !prev);
  };

  const getContent = () => {
    if (isApiKeysConnection) {
      return (
        <Form
          returnToAllExchanges={returnToAllExchanges}
          type='bybit'
          fields={[
            {
              label: 'Enter any name',
              value: exchangeTitle,
              placeholder: 'Name',
              onChange: handleChangeTitle,
            },
            {
              label: 'Paste your API key',
              value: apiKey,
              placeholder: 'Key',
              onChange: handleChangeApiKey,
            },
            {
              label: 'Paste your API secret',
              value: apiSecret,
              mask: true,
              placeholder: 'Secret',
              onChange: handleChangeApiSecret,
            },
          ]}
          actionBlock={(
            <ActionBlock
              button={{
                label: 'Connect Bybit',
                action: connectBybitWithApiKeys,
              }}
              link={{
                label: (
                  <Box display='flex' alignItems='center' gap={1}>
                    Connect with OAuth
                    {Verify}
                  </Box>
                ),
                action: switchForm,
              }}
              createAccount={{
                label: 'Create Bybit account',
                link: 'https://partner.bybit.com/b/skyrexio',
              }}
            />
          )}
        />
      );
    }

    return (
      <Info
        returnToAllExchanges={returnToAllExchanges}
        steps={[
          'Click on Connect Bybit button',
          'You will be redirected to Bybit',
          'Confirm your connection to Skyrex',
        ]}
        actionBlock={(
          <ActionBlock
            button={{
              label: 'Connect Bybit',
              action: connectBybit,
            }}
            link={{
              label: 'Connect with API keys',
              action: switchForm,
            }}
            createAccount={{
              label: 'Create Bybit account',
              link: 'https://partner.bybit.com/b/skyrexio',
            }}
          />
        )}
      />
    );
  };

  return (
    <>
      <ExchangeUpdatedModalStatus
        isOpen={modalOpen}
        onClose={handleCloseModal}
        title={'Exchange account connected successfully'}
        description={'Now you are in one click to start trading bot or create manual trade using your account'}
        action={handleCloseModal}
      />
      
      <ErrorConnectModal
        isOpen={connectExchangeError}
        title='Failed to connect account'
        description='Check API keys settings are correct and try again'
        closeHandler={handleCloseConnectErrorModal}
      />

      <Box position='relative'>
        {isLoading && (
          <Loader isContentOverflow={true} />
        )}

        <Box sx={getWrapperStyles(!!isModalView)}>
          <Box sx={logo}>
            <Title>Connect</Title>
            <Box sx={bybitLogo}>{BybitLogo}</Box>
            <Title>exchange</Title>
          </Box>
          {getContent()}
        </Box>

      </Box>
    </>
  );
};
