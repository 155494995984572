import { Box } from '@mui/material';
import { renderJson, stringifyJson } from 'shared/helpers';
import { Copy as CopyIcon } from 'shared/icons';
import { ICopy } from '../interfaces';
import { background, wrapper } from '../styles';

export const Copy = (props: ICopy) => {
  const {
    value,
    isCopy = true,
  } = props;

  const copyHandler = () => {
    if (!value) {
      return;
    }
    
    navigator.clipboard.writeText(stringifyJson(value));
  };

  return (
    <Box sx={background}>
      {renderJson(value)}

      {isCopy && (
        <div
          style={wrapper}
          onClick={copyHandler}
        >
          {CopyIcon}
        </div>
      )}
    </Box>
  );
};
