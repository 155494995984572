import { SxProps, Theme } from '@mui/material';

export const wrapper: SxProps<Theme> = {
  display: 'flex',
  gap: 4.25,
  flexWrap: {
    xs: 'wrap',
    md: 'nowrap',
  },
};
