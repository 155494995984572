import { Box } from '@mui/material';
import { Form, Segmented } from 'antd';
import dayjs from 'dayjs';
import { getBotStatistic } from 'pages/trading-bots/configurator/api';
import { MainContext } from 'pages/trading-bots/configurator/context';
import { formItem } from 'pages/trading-bots/configurator/styles';
import { Dispatch, SetStateAction, useContext, useState } from 'react';
import { SecondaryInfo } from 'shared/icons';
import { LabelInput, Switch, Text, Tooltip } from 'shared/ui';
import { ISharing } from '../interfaces';
import { inner, inputs, title, tooltipTitle, tooltipWrapper, wrapper } from '../styles';

export const Sharing = (props: ISharing) => {
  const {
    openHandler,
    bot,
  } = props;

  const {
    setInitialDataValue,
  } = useContext(MainContext);

  const [isOpened, setIsOpened] = useState<boolean>(bot?.minInvestment || false);
  const [selectedSegment, setSelectedSegment] = useState<string>(bot?.copyCommissionPercent ? 'free' : 'paid');
  const [profit, setProfit] = useState<string>(bot?.copyCommissionPercent || '20');
  const [minAmount, setMinAmount] = useState<string>(bot?.minInvestment || '');
  const [isLoading, setIsLoading] = useState<boolean>(false);

  const [form] = Form.useForm();

  const changeInputValue = (action: Dispatch<SetStateAction<string>>, field: string) => {
    return (value: string) => {
      action(value);
      
      setInitialDataValue((prev: any) => {
        return {
          ...prev,
          sharing: {
            ...prev.sharing,
            [field]: +value,
          },
        };
      });
    };
  };

  const changeSegment = (value: string) => {
    setSelectedSegment(value);

    let profitValue = 0;
    if (value !== 'free') {
      profitValue = +profit;
    }

    setInitialDataValue((prev: any) => {
      return {
        ...prev,
        sharing: {
          ...prev.sharing,
          type: value,
          profit: profitValue,
        },
      };
    });
  };

  const changeHandler = async () => {
    if (dayjs().diff(dayjs(bot?.createdDate), 'day') >= 30) {
      setIsLoading(true);
      const response = await getBotStatistic({
        botUuid: bot.botUuid,
        dates: [
          dayjs().subtract(30, 'day').startOf('day'),
          dayjs().endOf('day'),
        ],
      });
      setIsLoading(false);

      if (!response) {
        return;
      }

      if (response.roi >= 1 && response.totalTrades >= 10 && response.maxDrawdown >= -15) {
        setIsOpened(true);
        return;
      }

      openHandler();
      return;
    }

    openHandler();
  };

  return (
    <Form
      form={form}
      layout='vertical'
      initialValues={{
        minAmount,
        profit,
      }}
    >
      <Box sx={wrapper}>
        <Box sx={inner}>
          <Box
            display='flex'
            alignItems='center'
            gap='20px'
          >
            <Switch
              size='small'
              value={isOpened}
              onChange={changeHandler}
              disabled={isLoading}
              suffixText={(
                <Text styles={title}>
                  Sharing
                </Text>
              )}
            />

            <Tooltip title=''>
              {SecondaryInfo}
            </Tooltip>
          </Box>

          {isOpened && (
            <Segmented
              value={selectedSegment}
              onChange={changeSegment}
              options={[{
                label: 'Free',
                value: 'free',
              }, {
                label: 'Paid',
                value: 'paid',
              }]}
            />
          )}
        </Box>

        {isOpened && (
          <Box sx={inputs}>
            {selectedSegment === 'paid' && (
              <Form.Item
                name='profit'
                style={formItem}
                rules={[{
                  required: true,
                  message: 'Profit sharing is required',
                }, {
                  validator: (_, value) => {
                    if (+value > 0 && Number.isInteger(+value)) {
                      return Promise.resolve();
                    }
  
                    return Promise.reject(new Error('Should be positive integer'));
                  },
                }]}
              >
                <LabelInput
                  label={(
                    <Box sx={tooltipWrapper}>
                      <Text styles={tooltipTitle}>
                        Profit sharing, %
                      </Text>
            
                      <Tooltip title=''>
                        {SecondaryInfo}
                      </Tooltip>
                    </Box>
                  )}
                  value={profit}
                  placeholder='Specify profit sharing percent'
                  onChange={changeInputValue(setProfit, 'profit')}
                />
              </Form.Item>
            )}

            <Form.Item
              name='minAmount'
              style={formItem}
              rules={[{
                required: true,
                message: 'Min amount is required',
              }, {
                validator: (_, value) => {
                  if (+value > 0) {
                    return Promise.resolve();
                  }

                  return Promise.reject(new Error('Should be positive'));
                },
              }]}
            >
              <LabelInput
                label={(
                  <Box sx={tooltipWrapper}>
                    <Text styles={tooltipTitle}>
                      Min amount
                    </Text>

                    <Tooltip title=''>
                      {SecondaryInfo}
                    </Tooltip>
                  </Box>
                )}
                value={minAmount}
                placeholder='Specify min amount'
                onChange={changeInputValue(setMinAmount, 'minAmount')}
              />
            </Form.Item>
          </Box>
        )}
      </Box>
    </Form>
  );
};
