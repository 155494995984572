import { Box } from '@mui/material';
import { CloseSecondaryIcon } from 'shared/icons';
import { Text } from 'shared/ui';
import { closeWrapper, inner, item, itemTitle, itemValue, wrapper } from '../styles';

export const SafetyOrders = () => {
  return (
    <Box sx={wrapper}>
      <Box sx={inner}>
        <Box sx={item}>
          <Text styles={itemTitle}>Price</Text>
          <Text styles={itemTitle}>Volume</Text>
        </Box>

        <Box sx={item}>
          <Text styles={itemValue}>0.0266 USDT</Text>
          <Text styles={itemValue}>10%</Text>

          <div style={closeWrapper}>
            {CloseSecondaryIcon}
          </div>
        </Box>
      </Box>
    </Box>
  );
};
