import { Box } from '@mui/material';
import { Modal } from 'antd';
import { MainButton, Text, Title } from 'shared/ui';
import { IBotEligible } from '../interfaces';
import {
  button,
  content,
  description,
  image,
  inner,
  item,
  list,
  modal,
  modalChildren,
  title,
  wrapper,
} from '../styles';

export const BotEligible = (props: IBotEligible) => {
  const {
    isOpened,
    closeHandler,
  } = props;

  return (
    <Modal
      open={isOpened}
      footer={null}
      style={modal}
      styles={modalChildren}
      closable={true}
      destroyOnClose={true}
      onCancel={closeHandler}
      width='100%'
      centered={true}
    >
      <Box sx={wrapper}>
        <Box sx={image}>
          <img src='/images/trading-bots/bot-eligible.png' alt='bot-eligible' />
        </Box>

        <Box sx={inner}>
          <Title styles={title}>
            Bot is not eligible yet
          </Title>

          <Box sx={content}>
            <Text styles={description}>
              Bot should run more than 30 days and have statistic:
            </Text>

            <ul style={list}>
              <li style={item}>
                ROI greater than 1%
              </li>
              <li style={item}>
                Max drawdown less than -15%
              </li>
              <li style={item}>
                Total trades more than 10
              </li>
            </ul>
          </Box>

          <MainButton
            type='primary'
            styles={button}
          >
            Okay
          </MainButton>
        </Box>
      </Box>
    </Modal>
  );
};
