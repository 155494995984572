import { Box } from '@mui/material';
import { Form, Segmented } from 'antd';
import { baseOrderTypes, conditionalPriceTypes } from 'pages/trading-bots/configurator/consts';
import { MainContext } from 'pages/trading-bots/configurator/context';
import { formItem } from 'pages/trading-bots/configurator/styles';
import { Dispatch, Fragment, SetStateAction, useContext, useEffect, useState } from 'react';
import { formatFloat, formatPositiveInteger } from 'shared/helpers';
import { SecondaryInfo } from 'shared/icons';
import { LabelInput, SingleSelect, Switch, Text, Tooltip } from 'shared/ui';
import { blockWrapper, inputs, switchText, title, tooltipTitle, tooltipWrapper, topWrapper, wrapper } from '../styles';

const takeFilteredOrderTypes = baseOrderTypes.filter((item) => item.value !== 'MARKET' && item.value !== 'CONDITIONAL_LIMIT');
const stopFilteredOrderTypes = baseOrderTypes.filter((item) => item.value.includes('CONDITIONAL'));
const alertFilteredOrderTypes = baseOrderTypes.filter((item) => !item.value.includes('CONDITIONAL'));

export const Position = () => {
  const {
    bot,
    setInitialDataValue,
  } = useContext(MainContext);

  const [selectedSegment, setSelectedSegment] = useState<string>(bot?.alertCloseType ? 'alert' : 'preset');
  const [takeProfitOpened, setTakeProfitOpened] = useState<boolean>(bot ? !!bot?.takeProfitTargetsQuantity : true);
  const [stopLossOpened, setStopLossOpened] = useState<boolean>(bot ? bot?.stopLossPercent !== 0 : true);
  const [orders, setOrders] = useState<string>(bot?.takeProfitTargetsQuantity || '1');
  const [orderSize, setOrderSize] = useState<string>(bot?.takeProfitVolumeStepCoefficient || '1');
  const [orderPrice, setOrderPrice] = useState<string>(bot?.takeProfitPriceStepCoefficient || '1');
  const [takePriceChange, setTakePriceChange] = useState<string>(bot?.takeProfitPricePercent || '5');
  const [stopPriceChange, setStopPriceChange] = useState<string>(bot?.stopLossPercent || '-5');
  const [takeOrderType, setTakeOrderType] = useState<string>(bot?.takeProfitType || takeFilteredOrderTypes[0].value);
  const [stopOrderType, setStopOrderType] = useState<string>(bot?.stopLossType || stopFilteredOrderTypes[0].value);
  const [alertOrderType, setAlertOrderType] = useState<string>(bot?.alertCloseType || alertFilteredOrderTypes[0].value);
  const [takePriceType, setTakePriceType] = useState<string>(bot?.takeProfitConditionalTriggerType || conditionalPriceTypes[0].value);
  const [stopPriceType, setStopPriceType] = useState<string>(bot?.stopLossConditionalTriggerType || conditionalPriceTypes[0].value);

  const [form] = Form.useForm();

  const changeInputValue = (action: Dispatch<SetStateAction<string>>, field: string) => {
    return (value: string) => {
      const [type, key] = field.split('.');

      const replacedValue = key === 'orders' ? formatPositiveInteger(value) : formatFloat(value);

      action(replacedValue);
      form.setFieldValue(field, replacedValue);
      setInitialDataValue((prev: any) => {
        return {
          ...prev,
          closeOrders: {
            ...prev.closeOrders,
            [type]: {
              ...prev.closeOrders[type],
              [key]: replacedValue,
            },
          },
        };
      });
    };
  };

  const changeSelectValue = (action: Dispatch<SetStateAction<string>>, field: string) => {
    return (value: string) => {
      action(value);

      const [type, key] = field.split('.');
      let priceType = '';
      if (type === 'takeProfit' && key === 'orderType' && value === 'CONDITIONAL_MARKET') {
        priceType = takePriceType;
      }

      if (type === 'stopLoss' && key === 'orderType') {
        priceType = stopPriceType;
      }

      if ((type === 'takeProfit' || type === 'stopLoss') && key === 'conditionalPriceType') {
        priceType = value;
      }

      setInitialDataValue((prev: any) => {
        return {
          ...prev,
          closeOrders: {
            ...prev.closeOrders,
            [type]: {
              ...prev.closeOrders[type],
              [key]: value,
              conditionalPriceType: priceType,
            },
          },
        };
      });
    };
  };

  const openBlockHandler = (action: Dispatch<SetStateAction<boolean>>, type: string) => {
    return (value: boolean) => {
      action(value);

      setInitialDataValue((prev: any) => {
        return {
          ...prev,
          closeOrders: {
            ...prev.closeOrders,
            [type]: {
              ...prev.closeOrders[type],
              isOpened: value,
            },
          },
        };
      });
    };
  };

  const changeSegment = (value: string) => {
    setSelectedSegment(value);

    setInitialDataValue((prev: any) => {
      return {
        ...prev,
        closeOrders: {
          ...prev.closeOrders,
          type: value,
          orderType: alertOrderType,
          alert: {
            alertOrderType,
          },
        },
      };
    });
  };

  const getContent = () => {
    if (selectedSegment === 'preset') {
      return (
        <Fragment>
          <Box sx={blockWrapper}>
            <Switch
              value={takeProfitOpened}
              size='small'
              suffixText={(
                <Text styles={switchText}>
                  Take profit
                </Text>
              )}
              onChange={openBlockHandler(setTakeProfitOpened, 'takeProfit')}
            />

            {takeProfitOpened && (
              <Box sx={inputs}>
                <Form.Item
                  name='takeProfit.orders'
                  style={formItem}
                  rules={[{
                    required: true,
                    message: 'Orders is required',
                  }, {
                    validator(_, value) {
                      if (!Number.isInteger(+value) || +value === 0) {
                        return Promise.reject('Orders should be positive integer');
                      }

                      if (+value > 5) {
                        return Promise.reject('Orders should be less than 5');
                      }

                      return Promise.resolve();
                    },
                  }]}
                >
                  <LabelInput
                    label={(
                      <Box sx={tooltipWrapper}>
                        <Text styles={tooltipTitle}>
                          Orders
                        </Text>

                        <Tooltip title=''>
                          {SecondaryInfo}
                        </Tooltip>
                      </Box>
                    )}
                    value={orders}
                    placeholder=''
                    onChange={changeInputValue(setOrders, 'takeProfit.orders')}
                  />
                </Form.Item>

                <Form.Item
                  name='takeProfit.priceChange'
                  style={formItem}
                  rules={[{
                    required: true,
                    message: 'Price change is required',
                  }, {
                    validator(_, value) {
                      if (+value > 0) {
                        return Promise.resolve();
                      }

                      return Promise.reject('Price change should be positive');
                    },
                  }]}
                >
                  <LabelInput
                    label={(
                      <Box sx={tooltipWrapper}>
                        <Text styles={tooltipTitle}>
                          Price change, %
                        </Text>

                        <Tooltip title=''>
                          {SecondaryInfo}
                        </Tooltip>
                      </Box>
                    )}
                    value={takePriceChange}
                    placeholder=''
                    onChange={changeInputValue(setTakePriceChange, 'takeProfit.priceChange')}
                  />
                </Form.Item>

                <SingleSelect
                  label={(
                    <Box sx={tooltipWrapper}>
                      <Text styles={tooltipTitle}>
                        Order type
                      </Text>

                      <Tooltip title=''>
                        {SecondaryInfo}
                      </Tooltip>
                    </Box>
                  )}
                  options={takeFilteredOrderTypes}
                  select={{
                    value: takeOrderType,
                    onChange: changeSelectValue(setTakeOrderType, 'takeProfit.orderType'),
                    placeholder: '',
                  }}
                />

                {+orders > 1 && (
                  <Fragment>
                    <Form.Item
                      name='takeProfit.orderSizeScale'
                      style={formItem}
                      rules={[{
                        required: true,
                        message: 'Order size scale is required',
                      }, {
                        validator(_, value) {
                          if (+value > 0) {
                            return Promise.resolve();
                          }

                          return Promise.reject('Order size scale should be positive');
                        },
                      }]}
                    >
                      <LabelInput
                        label={(
                          <Box sx={tooltipWrapper}>
                            <Text styles={tooltipTitle}>
                              Order size scale
                            </Text>

                            <Tooltip title=''>
                              {SecondaryInfo}
                            </Tooltip>
                          </Box>
                        )}
                        value={orderSize}
                        placeholder=''
                        onChange={changeInputValue(setOrderSize, 'takeProfit.orderSizeScale')}
                      />
                    </Form.Item>

                    <Form.Item
                      name='takeProfit.orderPriceChangeScale'
                      style={formItem}
                      rules={[{
                        required: true,
                        message: 'Order price change scale is required',
                      }, {
                        validator(_, value) {
                          if (+value > 0) {
                            return Promise.resolve();
                          }

                          return Promise.reject('Order price change scale should be positive');
                        },
                      }]}
                    >
                      <LabelInput
                        label={(
                          <Box sx={tooltipWrapper}>
                            <Text styles={tooltipTitle}>
                              Order price change scale
                            </Text>

                            <Tooltip title=''>
                              {SecondaryInfo}
                            </Tooltip>
                          </Box>
                        )}
                        value={orderPrice}
                        placeholder=''
                        onChange={changeInputValue(setOrderPrice, 'takeProfit.orderPriceChangeScale')}
                      />
                    </Form.Item>
                  </Fragment>
                )}


                {takeOrderType !== 'LIMIT' && (
                  <SingleSelect
                    label={(
                      <Box sx={tooltipWrapper}>
                        <Text styles={tooltipTitle}>
                          Conditional price type
                        </Text>

                        <Tooltip title=''>
                          {SecondaryInfo}
                        </Tooltip>
                      </Box>
                    )}
                    options={conditionalPriceTypes}
                    select={{
                      value: takePriceType,
                      onChange: changeSelectValue(setTakePriceType, 'takeProfit.conditionalPriceType'),
                      placeholder: '',
                    }}
                  />
                )}
              </Box>
            )}
          </Box>

          <Box sx={blockWrapper}>
            <Switch
              value={stopLossOpened}
              size='small'
              suffixText={(
                <Text styles={switchText}>
                  Stop loss
                </Text>
              )}
              onChange={openBlockHandler(setStopLossOpened, 'stopLoss')}
            />

            {stopLossOpened && (
              <Box sx={inputs}>
                <Form.Item
                  name='stopLoss.priceChange'
                  style={formItem}
                  rules={[{
                    required: true,
                    message: 'Price change is required',
                  }, {
                    validator(_, value) {
                      if (+value < 0) {
                        return Promise.resolve();
                      }

                      return Promise.reject('Price change should be negative');
                    },
                  }]}
                >
                  <LabelInput
                    label={(
                      <Box sx={tooltipWrapper}>
                        <Text styles={tooltipTitle}>
                          Price change, %
                        </Text>

                        <Tooltip title=''>
                          {SecondaryInfo}
                        </Tooltip>
                      </Box>
                    )}
                    value={stopPriceChange}
                    placeholder=''
                    onChange={changeInputValue(setStopPriceChange, 'stopLoss.priceChange')}
                  />
                </Form.Item>

                <SingleSelect
                  label={(
                    <Box sx={tooltipWrapper}>
                      <Text styles={tooltipTitle}>
                        Order type
                      </Text>

                      <Tooltip title=''>
                        {SecondaryInfo}
                      </Tooltip>
                    </Box>
                  )}
                  options={stopFilteredOrderTypes}
                  select={{
                    value: stopOrderType,
                    onChange: changeSelectValue(setStopOrderType, 'stopLoss.orderType'),
                    placeholder: '',
                  }}
                />

                <SingleSelect
                  label={(
                    <Box sx={tooltipWrapper}>
                      <Text styles={tooltipTitle}>
                        Conditional price type
                      </Text>

                      <Tooltip title=''>
                        {SecondaryInfo}
                      </Tooltip>
                    </Box>
                  )}
                  options={conditionalPriceTypes}
                  select={{
                    value: stopPriceType,
                    onChange: changeSelectValue(setStopPriceType, 'stopLoss.conditionalPriceType'),
                    placeholder: '',
                  }}
                />
              </Box>
            )}
          </Box>
        </Fragment>
      );
    }

    return (
      <Box sx={inputs}>
        <SingleSelect
          label={(
            <Box sx={tooltipWrapper}>
              <Text styles={tooltipTitle}>
                Order type
              </Text>

              <Tooltip title=''>
                {SecondaryInfo}
              </Tooltip>
            </Box>
          )}
          options={alertFilteredOrderTypes}
          select={{
            value: alertOrderType,
            onChange: changeSelectValue(setAlertOrderType, 'alert.alertOrderType'),
            placeholder: '',
          }}
        />
      </Box>
    );
  };

  useEffect(() => {
    setInitialDataValue((prev: any) => {
      return {
        ...prev,
        closeOrders: {
          ...prev.closeOrders,
          takeProfit: {
            ...prev.closeOrders.takeProfit,
            orderType: takeOrderType,
          },
          stopLoss: {
            ...prev.closeOrders.stopLoss,
            orderType: stopOrderType,
            conditionalPriceType: stopPriceType,
          },
        },
      };
    });
  }, []);

  return (
    <Form
      form={form}
      layout='vertical'
      initialValues={{
        'takeProfit.orders': orders,
        'takeProfit.priceChange': takePriceChange,
        'stopLoss.priceChange': stopPriceChange,
        'takeProfit.orderSizeScale': orderSize,
        'takeProfit.orderPriceChangeScale': orderPrice,
      }}
    >
      <Box sx={wrapper}>
        <Box sx={topWrapper}>
          <Text styles={title}>
            Close orders
          </Text>

          <Segmented
            value={selectedSegment}
            onChange={changeSegment}
            options={[{
              label: 'Preset',
              value: 'preset',
            }, {
              label: 'Alert',
              value: 'alert',
            }]}
          />
        </Box>

        {getContent()}
      </Box>
    </Form>
  );
};
