import { Box } from '@mui/material';
import { Fragment } from 'react/jsx-runtime';
import { MainButton, RangePicker, WithDrawRequest } from 'shared/ui';
import { button, wrapper } from '../styles';

export const WithDraw = () => {
  return (
    <Fragment>
      <Box sx={wrapper}>
        <RangePicker
          label='Period'
          value={[null, null]}
          maxWidth='100%'
        />

        <MainButton
          type='primary'
          ghost={true}
          styles={button}
        >
          Withdraw
        </MainButton>
      </Box>

      <WithDrawRequest />
    </Fragment>
  );
};
