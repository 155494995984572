import { Box } from '@mui/material';
import { Segmented } from 'antd';
import { useState } from 'react';
import { PlusGreen } from 'shared/icons';
import {
  Input,
  LabelInput,
  MainButton,
  SingleSelect,
  Slider,
  Switch,
  Text,
  Title,
} from 'shared/ui';
import { SafetyOrders } from '../components';
import { segments } from '../consts';
import { IAdditionalEntry } from '../interfaces';
import { action, actions, actionText, button, buttonTitle, suffixText, wrpaper } from '../styles';

export const AdditionalEntry = (props: IAdditionalEntry) => {
  const {
    inputs,
    sliderInput,
  } = props;

  const [selectedSegment, setSelectedSegment] = useState<string>(segments[0].value);
  
  const changeSegmentHandler = (value: string) => {
    setSelectedSegment(value);
  };

  const renderInputs = () => {
    const renderedInputs = inputs.map((input: IAdditionalEntry['inputs'][0], index: number) => {
      if (index === 0 && selectedSegment !== segments[0].value) {
        return (
          <Box
            key={input.label}
            sx={action}
          >
            <Text
              type='secondary'
              styles={actionText}
            >
              {input.label}
            </Text>
  
            <Box sx={actions}>
              <Input
                value={input.value}
                onChange={input.onChange}
                icon={input.suffixText}
                addonBefore='10%'
              />
  
              <SingleSelect
                options={[{
                  label: 'Last',
                  value: '',
                }, {
                  label: 'Bid',
                  value: '',
                }, {
                  label: 'Ask',
                  value: '',
                }]}
                maxWidth='max-content'
                select={{
                  placeholder: 'Last',
                  onChange: () => {},
                }}
              />
            </Box>
          </Box>
        );
      }

      if (index === 1 && selectedSegment !== segments[2].value) {
        return (
          <Box
            sx={action}
            key={input.label}
          >
            <Text
              type='secondary'
              styles={actionText}
            >
              {input.label}
            </Text>
    
            <Box sx={actions}>
              <Input
                value={input.value}
                onChange={input.onChange}
                icon={input.suffixText}
              />
    
              <Input
                value='100'
                placeholder='-5'
                maxWidth={58}
                onChange={() => {}}
                icon='%'
              />
            </Box>
          </Box>
        );
      }

      if (index === 0 || index === 1) {
        return null;
      }

      return (
        <LabelInput
          key={input.label}
          label={input.label}
          value={input.value}
          onChange={input.onChange}
          icon={input.suffixText}
        />
      );
    });

    return renderedInputs;
  };

  return (
    <Box sx={wrpaper}>
      <Switch
        suffixText={(
          <Title
            level={5}
            styles={suffixText}
          >
            Additional entry
          </Title>
        )}
        size='small'
      />

      <Segmented
        value={selectedSegment}
        onChange={changeSegmentHandler}
        options={segments}
        block={true}
      />

      <SafetyOrders />

      {renderInputs()}

      <Box sx={action}>
        <Box
          sx={{
            ...actions,
            alignItems: 'center',
          }}
        >
          <Slider
            value={+sliderInput.value}
            onChnage={sliderInput.onChange}
          />

          <Input
            value={sliderInput.value}
            placeholder='-5'
            maxWidth={58}
            onChange={sliderInput.onChange}
            icon='%'
          />
        </Box>
      </Box>

      <MainButton
        icon={PlusGreen}
        styles={button}
      >
        <Title
          level={5}
          styles={buttonTitle}
        >
          Add entry
        </Title>
      </MainButton>
    </Box>
  );
};
