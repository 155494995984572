import { Box } from '@mui/material';
import { Modal } from 'antd';
import { LabelInput, MainButton, Text, Title } from 'shared/ui';
import { button, description, inner, modal, modalChildren, title, topWrapper, wrapper } from '../styles';

export const WithDrawRequest = () => {
  return (
    <Modal
      open={false}
      footer={null}
      style={modal}
      styles={modalChildren}
      closable={true}
      destroyOnClose={true}
      // onCancel={handleCancel}
      width='100%'
      centered={true}
    >
      <Box sx={wrapper}>
        <Box sx={topWrapper}>
          <Title styles={title}>
            Withdrawal request
          </Title>

          <Text styles={description}>
            Provide TRC-20 wallet address only
          </Text>
        </Box>

        <Box sx={inner}>
          <LabelInput
            label='Amount to withdraw (available: 125 USDT)'
            value=''
            onChange={() => {}}
            placeholder=''
          />
          
          <LabelInput
            label='TRC-20 wallet'
            value=''
            onChange={() => {}}
            placeholder=''
          />

          <MainButton
            type='primary'
            ghost={true}
            styles={button}
          >
            Submit
          </MainButton>
        </Box>
      </Box>
    </Modal>
  );
};
