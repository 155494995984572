import { Box } from '@mui/material';
import { MainButton } from 'shared/ui';
import { BinanceLogo, ByBitLogo, CryptoIcon, GateLogo, OkxLogo } from '../icons';
import { ITabs } from '../interfaces';
import { background, binanceLogo, bybitLogo, cryptoLogo, gateLogo, icon, item, okxLogo } from '../styles';

export const Tabs = (props: ITabs) => {
  const {
    onChoose,
  } = props;

  const onClickHandler = (platform: string) => () => {
    onChoose(platform);
  };

  return (
    <Box sx={background}>
      <MainButton
        styles={item}
        ghost={true}
        onClick={onClickHandler('binance')}
      >
        <Box sx={icon}>
          <div style={binanceLogo}>
            {BinanceLogo}
          </div>
        </Box>
      </MainButton>

      <MainButton
        styles={item}
        ghost={true}
        onClick={onClickHandler('okx')}
      >
        <Box sx={icon}>
          <div style={okxLogo}>
            {OkxLogo}
          </div>
        </Box>
      </MainButton>

      <MainButton
        styles={item}
        ghost={true}
        onClick={onClickHandler('bybit')}
      >
        <Box sx={icon}>
          <div style={bybitLogo}>
            {ByBitLogo}
          </div>
        </Box>
      </MainButton>

      <MainButton
        styles={item}
        ghost={true}
        onClick={onClickHandler('gate')}
      >
        <Box sx={icon}>
          <div style={gateLogo}>
            {GateLogo}
          </div>
        </Box>
      </MainButton>

      <MainButton
        styles={item}
        ghost={true}
        onClick={onClickHandler('crypto')}
      >
        <Box sx={icon}>
          <div style={cryptoLogo}>
            {CryptoIcon}
          </div>
        </Box>
      </MainButton>
    </Box>
  );
};
