import { Box } from '@mui/material';
import { Form, Segmented } from 'antd';
import { baseOrderTypes, conditionalPriceTypes } from 'pages/trading-bots/configurator/consts';
import { MainContext } from 'pages/trading-bots/configurator/context';
import { formItem } from 'pages/trading-bots/configurator/styles';
import { Dispatch, SetStateAction, useContext, useState } from 'react';
import { formatFloat, formatPositiveInteger } from 'shared/helpers';
import { SecondaryInfo } from 'shared/icons';
import { LabelInput, SingleSelect, Switch, Text, Tooltip } from 'shared/ui';
import { inner, inputs, title, tooltipTitle, tooltipWrapper, wrapper } from '../styles';

const filteredOrderTypes = baseOrderTypes.filter((item) => item.value !== 'MARKET');
const alertFilteredOrderTypes = baseOrderTypes.filter((item) => !item.value.includes('CONDITIONAL'));

export const Additional = () => {
  const {
    bot,
    setInitialDataValue,
  } = useContext(MainContext);

  const [isOpen, setIsOpen] = useState<boolean>(!!bot?.maxSafetyOrders);
  const [selectedSegment, setSelectedSegment] = useState<string>(bot?.alertAdditionalBaseOrderType ? 'alert' : 'preset');
  const [maxOrders, setMaxOrders] = useState<string>(bot?.maxSafetyOrders || '1');
  const [priceChange, setPriceChange] = useState<string>(bot?.priceDeviationPercentSafety || '-3');
  const [orderType, setOrderType] = useState<string>(bot?.additionalBaseOrderType || filteredOrderTypes[0].value);
  const [alertOrderType, setAlertOrderType] = useState<string>(bot?.alertAdditionalBaseOrderType || alertFilteredOrderTypes[0].value);
  const [orderSize, setOrderSize] = useState<string>(bot?.martingaleVolumeCoefficient || '1');
  const [orderPrice, setOrderPrice] = useState<string>(bot?.martingalePriceStepCoefficient || '1');
  const [conditionalPriceType, setConditionalPriceType] = useState<string>(bot?.additionalBaseConditionalTriggerType || conditionalPriceTypes[0].value);

  const [form] = Form.useForm();

  const changeInputValue = (action: Dispatch<SetStateAction<string>>, field: string) => {
    return (value: string) => {
      const replacedValue = field === 'maxOrders' ? formatPositiveInteger(value) : formatFloat(value);

      action(replacedValue);
      form.setFieldValue(field, replacedValue);
      setInitialDataValue((prev: any) => {
        return {
          ...prev,
          additionalEntryOrders: {
            ...prev.additionalEntryOrders,
            [field]: replacedValue,
          },
        };
      });
    };
  };

  const changeSelectValue = (action: Dispatch<SetStateAction<string>>, field: string) => {
    return (value: string) => {
      action(value);

      let priceType = '';
      if (field === 'orderType' && value !== 'LIMIT') {
        priceType = conditionalPriceType;
      }

      if (field === 'conditionalPriceType') {
        priceType = value;
      }

      setInitialDataValue((prev: any) => {
        return {
          ...prev,
          additionalEntryOrders: {
            ...prev.additionalEntryOrders,
            [field]: value,
            conditionalPriceType: priceType,
          },
        };
      });
    };
  };

  const changeSegment = (value: string) => {
    setSelectedSegment(value);

    const isAlert = value === 'alert';

    setInitialDataValue((prev: any) => {
      return {
        ...prev,
        additionalEntryOrders: {
          ...prev.additionalEntryOrders,
          type: value,
          orderType: isAlert ? '' : orderType,
          alertOrderType: isAlert ? alertOrderType : '',
          orderPriceChangeScale: isAlert ? '' : orderPrice,
          priceChange: isAlert ? '' : priceChange,
        },
      };
    });
  };

  const openBlockHandler = (value: boolean) => {
    setIsOpen(value);
    setInitialDataValue((prev: any) => {
      return {
        ...prev,
        additionalEntryOrders: {
          ...prev.additionalEntryOrders,
          isOpened: value,
          orderType: value ? orderType : '',
        },
      };
    });
  };

  const getContent = () => {
    if (selectedSegment === 'preset') {
      return (
        <Box sx={inputs}>
          <Form.Item
            name='maxOrders'
            style={formItem}
            rules={[{
              required: true,
              message: 'Name is required',
            }, {
              validator(_, value) {
                if (+value > 0 && Number.isInteger(+value)) {
                  return Promise.resolve();
                }

                return Promise.reject('Should be positive integer');
              },
            }]}
          >
            <LabelInput
              label={(
                <Box sx={tooltipWrapper}>
                  <Text styles={tooltipTitle}>
                    Max orders
                  </Text>

                  <Tooltip title=''>
                    {SecondaryInfo}
                  </Tooltip>
                </Box>
              )}
              value={maxOrders}
              placeholder=''
              onChange={changeInputValue(setMaxOrders, 'maxOrders')}
            />
          </Form.Item>

          <Form.Item
            name='priceChange'
            style={formItem}
            rules={[{
              required: true,
              message: 'Price change is required',
            }, {
              validator(_, value) {
                if (+value < 0) {
                  return Promise.resolve();
                }

                return Promise.reject('Price change should be negative');
              },
            }]}
          >
            <LabelInput
              label={(
                <Box sx={tooltipWrapper}>
                  <Text styles={tooltipTitle}>
                    Price change, %
                  </Text>

                  <Tooltip title=''>
                    {SecondaryInfo}
                  </Tooltip>
                </Box>
              )}
              value={priceChange}
              placeholder=''
              onChange={changeInputValue(setPriceChange, 'priceChange')}
            />
          </Form.Item>

          <SingleSelect
            label={(
              <Box sx={tooltipWrapper}>
                <Text styles={tooltipTitle}>
                  Order type
                </Text>

                <Tooltip title=''>
                  {SecondaryInfo}
                </Tooltip>
              </Box>
            )}
            options={filteredOrderTypes}
            select={{
              value: orderType,
              onChange: changeSelectValue(setOrderType, 'orderType'),
              placeholder: '',
            }}
          />

          <Form.Item
            name='orderSizeScale'
            style={formItem}
            rules={[{
              required: true,
              message: 'Order size scale is required',
            }, {
              validator(_, value) {
                if (+value > 0) {
                  return Promise.resolve();
                }

                return Promise.reject('Order size scale should be positive');
              },
            }]}
          >
            <LabelInput
              label={(
                <Box sx={tooltipWrapper}>
                  <Text styles={tooltipTitle}>
                    Order size scale
                  </Text>

                  <Tooltip title=''>
                    {SecondaryInfo}
                  </Tooltip>
                </Box>
              )}
              value={orderSize}
              placeholder=''
              onChange={changeInputValue(setOrderSize, 'orderSizeScale')}
            />
          </Form.Item>

          {+maxOrders > 1 && (
            <Form.Item
              name='orderPriceChangeScale'
              style={formItem}
              rules={[{
                required: true,
                message: 'Order price change scale is required',
              }, {
                validator(_, value) {
                  if (+value > 0) {
                    return Promise.resolve();
                  }

                  return Promise.reject('Order price change scale should be positive');
                },
              }]}
            >
              <LabelInput
                label={(
                  <Box sx={tooltipWrapper}>
                    <Text styles={tooltipTitle}>
                      Order price change scale
                    </Text>

                    <Tooltip title=''>
                      {SecondaryInfo}
                    </Tooltip>
                  </Box>
                )}
                value={orderPrice}
                placeholder=''
                onChange={changeInputValue(setOrderPrice, 'orderPriceChangeScale')}
              />
            </Form.Item>
          )}

          {orderType !== 'LIMIT' && (
            <SingleSelect
              label={(
                <Box sx={tooltipWrapper}>
                  <Text styles={tooltipTitle}>
                    Conditional price type
                  </Text>

                  <Tooltip title=''>
                    {SecondaryInfo}
                  </Tooltip>
                </Box>
              )}
              options={conditionalPriceTypes}
              select={{
                value: conditionalPriceType,
                onChange: changeSelectValue(setConditionalPriceType, 'conditionalPriceType'),
                placeholder: '',
              }}
            />
          )}
        </Box>
      );
    }

    return (
      <Box sx={inputs}>
        <Form.Item
          name='maxOrders'
          style={formItem}
          rules={[{
            required: true,
            message: 'Name is required',
          }, {
            validator(_, value) {
              if (+value > 0 && Number.isInteger(+value)) {
                return Promise.resolve();
              }

              return Promise.reject('Should be positive integer');
            },
          }]}
        >
          <LabelInput
            label={(
              <Box sx={tooltipWrapper}>
                <Text styles={tooltipTitle}>
                  Max orders
                </Text>

                <Tooltip title=''>
                  {SecondaryInfo}
                </Tooltip>
              </Box>
            )}
            value={maxOrders}
            placeholder=''
            onChange={changeInputValue(setMaxOrders, 'maxOrders')}
          />
        </Form.Item>

        <Form.Item
          name='orderSizeScale'
          style={formItem}
          rules={[{
            required: true,
            message: 'Order size scale is required',
          }]}
        >
          <LabelInput
            label={(
              <Box sx={tooltipWrapper}>
                <Text styles={tooltipTitle}>
                  Order size scale
                </Text>

                <Tooltip title=''>
                  {SecondaryInfo}
                </Tooltip>
              </Box>
            )}
            value={orderSize}
            placeholder=''
            onChange={changeInputValue(setOrderSize, 'orderSizeScale')}
          />
        </Form.Item>

        <SingleSelect
          label={(
            <Box sx={tooltipWrapper}>
              <Text styles={tooltipTitle}>
                Order type
              </Text>

              <Tooltip title=''>
                {SecondaryInfo}
              </Tooltip>
            </Box>
          )}
          options={alertFilteredOrderTypes}
          select={{
            value: alertOrderType,
            onChange: changeSelectValue(setAlertOrderType, 'alertOrderType'),
            placeholder: '',
          }}
        />
      </Box>
    );
  };

  return (
    <Form
      form={form}
      layout='vertical'
      initialValues={{
        maxOrders: maxOrders,
        priceChange: priceChange,
        orderSizeScale: orderSize,
        orderPriceChangeScale: orderPrice,
      }}
    >
      <Box sx={wrapper}>
        <Box sx={inner}>
          <Switch
            value={isOpen}
            size='small'
            suffixText={(
              <Text styles={title}>
                Additional entry orders
              </Text>
            )}
            onChange={openBlockHandler}
          />

          {isOpen && (
            <Segmented
              value={selectedSegment}
              onChange={changeSegment}
              options={[{
                label: 'Preset',
                value: 'preset',
              }, {
                label: 'Alert',
                value: 'alert',
              }]}
            />
          )}
        </Box>

        {isOpen && (
          getContent()
        )}
      </Box>
    </Form>
  );
};
