import { SxProps, Theme } from '@mui/material';

export const icon: SxProps<Theme> = {
  width: 150,
  height: {
    xs: 50,
    md: 70,
  },
  cursor: 'pointer',
  display: 'flex',
  alignItems: 'center',
  justifyContent: 'center',
};
