import { Box } from '@mui/material';
import { Divider } from 'antd';
import { MainContext } from 'pages/trading-bots/configurator/context';
import { useContext, useEffect, useMemo } from 'react';
import { currencyFormatter } from 'shared/helpers/currency-formatter';
import { Text, Title } from 'shared/ui';
import { IEntryOrders } from '../interfaces';
import { content, header, row, table, tableWrapper, text, title, wrapper } from '../styles';

export const EntryOrders = (props: IEntryOrders) => {
  const {
    getBaseOrderValueAction,
    getFullPositionAction,
    setIsValidationError,
  } = props;

  const {
    initialDataValue: {
      main,
      additionalEntryOrders,
    },
  } = useContext(MainContext);

  const getBaseOrder = () => {
    const orderSizeScale = +additionalEntryOrders.orderSizeScale;
    const isAdditionalEntryOrdersOpened = additionalEntryOrders.isOpened;

    const additionalEntryOrdersValue = isAdditionalEntryOrdersOpened ? +additionalEntryOrders.maxOrders : 0;
    if (orderSizeScale === 1) {
      return +main.tradingAmount / (+main.maxActivePositions * (1 + additionalEntryOrdersValue));
    }

    return (+main.tradingAmount * (1 - orderSizeScale)) / (+main.maxActivePositions * (1 - orderSizeScale ** (1 + +additionalEntryOrders.maxOrders)));
  };

  const getPriceChange = (postion: number) => {
    if (additionalEntryOrders.type === 'alert') {
      return 'Alert';
    }

    const orderPriceChangeScale = +additionalEntryOrders.maxOrders === 1 ? 1 : +additionalEntryOrders.orderPriceChangeScale;

    if (orderPriceChangeScale === 1) {
      console.log('(+additionalEntryOrders.priceChange * postion)', (+additionalEntryOrders.priceChange * postion), additionalEntryOrders.priceChange);
      return `${(+additionalEntryOrders.priceChange * postion).toFixed(2)}%`;
    }

    return `${+(additionalEntryOrders.priceChange * ((1 - (orderPriceChangeScale ** postion)) / (1 - orderPriceChangeScale))).toFixed(2)}%`;
  };

  const items = useMemo(() => {
    if (!additionalEntryOrders.isOpened) {
      return [];
    }

    const formattedItems = new Array(+additionalEntryOrders.maxOrders).fill('').map((_, index) => {
      const position = ++index;

      const priceChange = getPriceChange(position);
      const result = getBaseOrder();

      let orderSize = 0;
      if (isFinite(result) && !isNaN(result)) {
        orderSize = result * (+additionalEntryOrders.maxOrders === 1 ? 1 : (additionalEntryOrders.orderSizeScale ** position));
      }

      return {
        position,
        priceChange,
        orderSize,
      };
    });

    return formattedItems;
  }, [additionalEntryOrders, main]);

  const getFullPostion = () => {
    if (!(main.tradingAmount && main.maxActivePositions)) {
      return 0;
    }

    const orderSizeSum = items.reduce((acc, next) => acc + next.orderSize, 0);
    return getBaseOrder() + orderSizeSum;
  };

  const getBaseOrderValue = () => {
    if (!main.tradingAmount) {
      return {
        message: 'Specify trading amount',
        value: 0,
      };
    }

    if (!main.maxActivePositions) {
      return {
        message: 'Specify max active positions',
        value: 0,
      };
    }

    const value = getBaseOrder();
    const resultValue = (isFinite(value) && !isNaN(value)) ? value : 0;

    return {
      value: resultValue,
      message: '',
    };
  };

  useEffect(() => {
    const fullPosition = getFullPostion();
    const baseOrder = getBaseOrder();

    // getBaseOrderValueAction(Number.isInteger(baseOrder) ? baseOrder : 0);
    // getFullPositionAction(Number.isInteger(fullPosition) ? fullPosition : 0);
    getBaseOrderValueAction((isFinite(baseOrder) && !isNaN(baseOrder)) ? baseOrder : 0);
    getFullPositionAction((isFinite(fullPosition) && !isNaN(fullPosition)) ? fullPosition : 0);
  }, [main, additionalEntryOrders]);

  const isBaseOrderError = main.filter > getBaseOrder();
  const isAdditionalEntryOrdersError = main.filter > (items?.at(-1)?.orderSize || 0);

  useEffect(() => {
    setIsValidationError(!!(isBaseOrderError || (additionalEntryOrders.isOpened && isAdditionalEntryOrdersError)));
  }, [isBaseOrderError, isAdditionalEntryOrdersError]);

  const baseOrderValue = getBaseOrderValue();

  return (
    <Box sx={wrapper}>
      <Title styles={title}>
        Entry orders
      </Title>

      <Box sx={row}>
        <Text styles={text}>
          Full position
        </Text>

        <Text
          type='secondary'
          styles={text}
        >
          {currencyFormatter(getFullPostion(), main.chartPrecision)} {main.market}
        </Text>
      </Box>

      <Divider
        style={{
          margin: 0,
        }}
      />

      <Box>
        <Box sx={row}>
          <Text
            styles={text}
            type={isBaseOrderError ? 'danger' : undefined}
          >
            Base order
          </Text>

          <Text
            type={!(main.tradingAmount && main.maxActivePositions) || isBaseOrderError ? 'danger' : undefined}
            styles={text}
          >
            {baseOrderValue.value ? `${currencyFormatter(baseOrderValue.value, main.chartPrecision)} ${main.market}` : baseOrderValue.message}
          </Text>
        </Box>

        {isBaseOrderError && (
          <Text
            type='danger'
            styles={text}
          >
            Order size is below the minimum
          </Text>
        )}
      </Box>

      {additionalEntryOrders.isOpened && (
        <Box sx={tableWrapper}>
          <Box
            display='flex'
            flexDirection='column'
          >
            <Text
              styles={text}
              type={isAdditionalEntryOrdersError ? 'danger' : undefined}
            >
              Additional entry orders
            </Text>

            {isAdditionalEntryOrdersError && (
              <Text
                type='danger'
                styles={text}
              >
                Order size is below the minimum
              </Text>
            )}
          </Box>

          <Box sx={table}>
            <Box sx={header}>
              <Text>
                №
              </Text>

              <Text>
                Price change
              </Text>

              <Text>
                Order size
              </Text>
            </Box>

            {items.map((item) => (
              <Box
                key={item.position}
                sx={content}
              >
                <Text>
                  {item.position}
                </Text>

                <Text>
                  {item.priceChange || 0}
                </Text>

                <Text>
                  {currencyFormatter(item.orderSize, main.chartPrecision)} {main.market}
                </Text>
              </Box>
            ))}
          </Box>
        </Box>
      )}
    </Box>
  );
};
