import { SxProps, Theme } from '@mui/material';

export const wrapper: SxProps<Theme> = {
  display: 'flex',
  flexDirection: 'column',
  gap: 3,
  width: '100%',
  flex: 1,
  maxWidth: 317,
};
