import { Box } from '@mui/material';
import { ExchangeAccount } from 'entities/exchange/model/types/exchange-account';
import { Dispatch, SetStateAction, useEffect, useState } from 'react';
import { useNavigate, useParams } from 'react-router-dom';
import { getSkyrexUuid } from 'shared/helpers/storage-helper';
import { BotEligible, EmptySubscription, Link, Loader, MainButton, ManagePosition, Text, Title } from 'shared/ui';
import { createBot, getBotInfo, getExchangesIds, updateBot } from '../api';
import {
  Additional,
  EntryOrders,
  Main,
  Position,
  PositionClose,
  Sharing,
} from '../components';
import { initialData, MainContext } from '../context';
import { ICreateBot } from '../interfaces';
import {
  block,
  blockRight,
  bottomWrapper,
  button,
  inner,
  links,
  title,
  wrapper,
} from '../styles';

export const Configurator = (props: any) => {
  const {
    isEdit,
  } = props;

  const [accounts, setAccounts] = useState<ExchangeAccount[]>([]);
  const [initialDataValue, setInitialDataValue] = useState<any>(initialData);
  const [baseOrder, setBaseOrder] = useState<number>(0);
  const [fullPosition, setFullPosition] = useState<number>(0);
  const [isBotEligibleOpened, setIsBotEligibleOpened] = useState<boolean>(false);
  const [isAlertSourceOpened, setIsAlertSourceOpened] = useState<boolean>(false);
  const [isError, setIsError] = useState<boolean>(false);
  const [isValidationError, setIsValidationError] = useState<boolean>(true);
  const [bot, setBot] = useState<any>(null);

  const navigate = useNavigate();
  const {
    id,
  } = useParams();

  const modalHandler = (action: Dispatch<SetStateAction<boolean>>) => {
    return () => {
      action((prev: boolean) => !prev);
    };
  };

  const createBotHandler = async () => {
    const {
      main,
      additionalEntryOrders,
      closeOrders,
      sharing,
    } = initialDataValue;

    const {
      takeProfit,
      stopLoss,
    } = closeOrders;

    const exchangeCode = accounts.find((account) => account.exchangeAccountUuid === main.exchangeAccount)?.exchangeCode!;

    const isAdditionalEntryOrdersOpened = additionalEntryOrders.isOpened;
    const isTakeProfitOpened = takeProfit.isOpened;
    const isStopLossOpened = stopLoss.isOpened;

    const isAdditionalEntryOrdersAlert = additionalEntryOrders.type === 'alert';
    const isCloseOrdersAlert = closeOrders.type === 'alert';

    // TODO: fix hardcode alertCloseType === LIMIT
    // minInvestment, copyCommissionPercent, copyCommissionFlat remove
    const params: ICreateBot = {
      name: main.name,
      skyrexUserUuid: getSkyrexUuid() as string,
      exchangeAccountUuid: main.exchangeAccount,
      botType: 'CUSTOM',
      allowSharing: 'FALSE',
      exchangeCode,
      quote: main.market,
      minInvestment: null,
      copyCommissionPercent: 0,
      copyCommissionFlat: 0,
      tradingAmount: +main.tradingAmount,
      maxActiveDeals: +main.maxActivePositions,
      martingaleVolumeCoefficient: 1,
      maxSafetyOrders: 0,
      priceDeviationPercentSafety: 0,
      martingalePriceStepCoefficient: 0,
      strategy: 'LONG',
      baseOrderType: main.baseOrderType,
      baseOrderConditionalTriggerType: null,
      additionalBaseOrderType: null,
      additionalBaseConditionalTriggerType: null,
      takeProfitPriceRecalculation: 'FROM_AVERAGE',
      takeProfitType: null,
      takeProfitConditionalTriggerType: null,
      takeProfitPricePercent: 0,
      takeProfitPriceStepCoefficient: 1,
      takeProfitTargetsQuantity: 0,
      takeProfitVolumeStepCoefficient: 1,
      stopLossPriceRecalculation: 'FROM_AVERAGE',
      stopLossType: null,
      stopLossConditionalTriggerType: null,
      stopLossPercent: 0,
      alertAdditionalBaseOrderType: null,
      alertCloseType: null,
    };

    if (bot) {
      params.botUuid = bot.botUuid;
    }

    if (isAdditionalEntryOrdersOpened && !isAdditionalEntryOrdersAlert) {
      params.additionalBaseOrderType = additionalEntryOrders.orderType;
      params.martingaleVolumeCoefficient = +additionalEntryOrders.orderSizeScale;
      params.maxSafetyOrders = +additionalEntryOrders.maxOrders;
      params.priceDeviationPercentSafety = +additionalEntryOrders.priceChange;
      params.martingalePriceStepCoefficient = +additionalEntryOrders.orderPriceChangeScale;
    }

    if (isAdditionalEntryOrdersAlert) {
      params.alertAdditionalBaseOrderType = additionalEntryOrders.alertOrderType;
      params.martingaleVolumeCoefficient = +additionalEntryOrders.orderSizeScale;
      params.maxSafetyOrders = +additionalEntryOrders.maxOrders;
    }

    if (isCloseOrdersAlert) {
      params.alertCloseType = closeOrders.alert.alertOrderType;
    }

    if (isAdditionalEntryOrdersOpened && additionalEntryOrders.orderType !== 'LIMIT' && !isAdditionalEntryOrdersAlert) {
      params.additionalBaseConditionalTriggerType = additionalEntryOrders.conditionalPriceType;
    }

    if (!isCloseOrdersAlert && isTakeProfitOpened) {
      params.takeProfitType = takeProfit.orderType;
    }

    if (!isCloseOrdersAlert && isTakeProfitOpened && takeProfit.orderType !== 'LIMIT') {
      params.takeProfitConditionalTriggerType = takeProfit.conditionalPriceType;
    }

    if (!isCloseOrdersAlert && isStopLossOpened) {
      params.stopLossType = stopLoss.orderType;
      params.stopLossConditionalTriggerType = stopLoss.conditionalPriceType;
    }

    const types = [
      'CONDITIONAL_LIMIT',
      'CONDITIONAL_MARKET',
    ];
    if (types.includes(main.baseOrderType)) {
      params.baseOrderConditionalTriggerType = main.baseOrderConditionalTriggerType;
    }

    if (!isCloseOrdersAlert && isTakeProfitOpened) {
      params.takeProfitPricePercent = +takeProfit.priceChange;
      params.takeProfitPriceStepCoefficient = +takeProfit.orderPriceChangeScale;
      params.takeProfitTargetsQuantity = +takeProfit.orders;
      params.takeProfitVolumeStepCoefficient = +takeProfit.orderSizeScale;
    }

    if (!isCloseOrdersAlert && isStopLossOpened) {
      params.stopLossPercent = +stopLoss.priceChange;
    }

    if (sharing.isOpened) {
      params.minInvestment = sharing.minAmount;
    }

    if (sharing.isOpened && sharing.type === 'paid') {
      params.copyCommissionPercent = sharing.profit;
    }

    const response = await (bot ? updateBot(params) : createBot(params));
    if (!response.success) {
      setIsError(true);
      return;
    }
    
    navigate(`/trading-bots/my-bots/bot/${response.data.fields.botUuid}`);
  };

  const getExchangesInfo = async () => {
    const userId = getSkyrexUuid();
    const {
      data: {
        accounts,
      },
    } = await getExchangesIds(userId as string);

    if (!accounts.length) {
      return;
    }

    if (id && isEdit) {
      const botInfoResponse = await getBotInfo({
        botUuid: id,
      });

      if (!botInfoResponse?.length) {
        return;
      }

      const [bot] = botInfoResponse;
      setBot(bot);

      const exchangeAccount = accounts.find((account: any) => account.exchangeAccountUuid === bot.exchangeAccountUuid);
      setAccounts([exchangeAccount]);

      setInitialDataValue((prev: any) => {
        const additionalEntryOrders = {
          ...prev.additionalEntryOrders,
          isOpened: !!bot.maxSafetyOrders,
          type: bot.alertAdditionalBaseOrderType ? 'alert' : 'preset',
          maxOrders: bot.maxSafetyOrders,
          priceChange: bot.priceDeviationPercentSafety || -3,
          orderType: bot.additionalBaseOrderType,
          alertOrderType: bot.alertAdditionalBaseOrderType,
          orderSizeScale: bot.martingaleVolumeCoefficient,
          orderPriceChangeScale: bot.martingalePriceStepCoefficient,
          conditionalPriceType: bot.additionalBaseConditionalTriggerType,
        };

        const closeOrders = {
          ...prev.closeOrders,
          orderType: bot.alertCloseType,
          type: bot.alertCloseType ? 'alert' : 'preset',
          alert: {
            alertOrderType: bot.alertCloseType,
          },
          takeProfit: {
            ...prev.closeOrders.takeProfit,
            isOpened: !!bot?.takeProfitTargetsQuantity,
            orders: bot.takeProfitTargetsQuantity,
            priceChange: bot.takeProfitPricePercent,
            orderType: bot.takeProfitType,
            orderSizeScale: bot.takeProfitVolumeStepCoefficient,
            orderPriceChangeScale: bot.takeProfitPriceStepCoefficient,
            conditionalPriceType: bot.takeProfitConditionalTriggerType,
          },
          stopLoss: {
            ...prev.closeOrders.stopLoss,
            isOpened: bot?.stopLossPercent !== 0,
            priceChange: bot.stopLossPercent,
            orderType: bot.stopLossType,
            conditionalPriceType: bot.stopLossConditionalTriggerType,
          },
        };

        const sharing = {
          ...prev.sharing,
          isOpened: !!bot.minInvestment,
          minAmount: bot.minInvestment || 0,
          profit: bot.copyCommissionPercent || 0,
          type: bot.copyCommissionPercent ? 'free' : 'paid',
        };

        return {
          ...prev,
          additionalEntryOrders,
          closeOrders,
          sharing,
        };
      });

      return;
    }

    setAccounts(accounts);
  };

  useEffect(() => {
    getExchangesInfo();
  }, [id]);

  if (!accounts.length) {
    return (
      <Loader isContentOverflow={true} />
    );
  }

  return (
    <Box sx={wrapper}>
      <Title styles={title}>
        Bot configurator
      </Title>

      <BotEligible
        isOpened={isBotEligibleOpened}
        closeHandler={modalHandler(setIsBotEligibleOpened)}
      />

      <ManagePosition
        isOpen={isAlertSourceOpened}
        handleClose={modalHandler(setIsAlertSourceOpened)}
      />

      <EmptySubscription
        isOpen={isError} 
        handleClose={modalHandler(setIsError)}
        modalTitle='Failed to create bot'
        modalDescription='Ensure the parameters are correct or contact support'
        modalButtonTitle='Ok'
        modalButtonAction={modalHandler(setIsError)}
      />

      <Box sx={inner}>
        <MainContext.Provider value={{
          bot,
          initialDataValue,
          setInitialDataValue,
        }}>
          <Box sx={block}>
            <Main
              accounts={accounts}
              quote={bot?.quote || ''}
            />
            <Additional />
            <Position />
            <Sharing
              openHandler={modalHandler(setIsBotEligibleOpened)}
              bot={bot}
            />
          </Box>

          <Box sx={blockRight}>
            <EntryOrders
              getBaseOrderValueAction={setBaseOrder}
              getFullPositionAction={setFullPosition}
              setIsValidationError={setIsValidationError}
            />

            <PositionClose
              fullPosition={fullPosition}
              baseOrder={baseOrder}
              setIsValidationError={setIsValidationError}
            />

            <Box sx={bottomWrapper}>
              <MainButton
                type='primary'
                styles={button}
                onClick={createBotHandler}
                disabled={isValidationError}
              >
                {bot ? 'Update' : 'Create'} Bot
              </MainButton>

              <Box sx={links}>
                <Text
                  type='success'
                  onClick={modalHandler(setIsAlertSourceOpened)}
                  styles={{
                    textDecoration: 'underline',
                  }}
                >
                  How to initiate and manage position using alerts
                </Text>

                <Link
                  type='success'
                  href='https://www.tradingview.com/u/Skyrexio/#published-scripts'
                  rel='noreferer'
                  target='_blank'
                  styles={{
                    textDecoration: 'underline',
                  }}
                >
                  Skyrexio strategy library on TradingView
                </Link>

                <Text>
                  Copy, customize and link open-source PineScript strategies to your bot. For any questions, use the support button
                </Text>
              </Box>
            </Box>
          </Box>
        </MainContext.Provider>
      </Box>
    </Box>
  );
};
