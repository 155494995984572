//@ts-nocheck

import axios from 'axios';
import { adaptBinanceSymbol, adaptBybitSymbol, adaptCryptoComSymbol, adaptGateIOSymbol, adaptOkxSymbol } from 'pages/trading-terminal-page/trading-chart/helpers/adapt-symbols';

export async function fetchBinanceExchangeInfo() {
  const response = await axios.get(
    'https://api.binance.com/api/v3/exchangeInfo',
  );
  return response.data.symbols
    .filter((symbol) => symbol.status === 'TRADING')
    .map(adaptBinanceSymbol);
}
  
// Fetch information from Bybit (implement similar to Binance)
export async function fetchBybitExchangeInfo() {
  const response = await axios.get(
    'https://api.bybit.com/v5/market/instruments-info?category=spot',
  );
    // return response.data.result.list.filter(symbol => symbol.status === 'Trading');
  return response.data.result.list
    .filter((symbol) => symbol.status === 'Trading')
    .map(adaptBybitSymbol);
  // Implement the fetching logic here
  // Ensure the output format is similar to fetchBinanceExchangeInfo()
}
  
// Fetch information from OKX (implement similar to Binance)
export async function fetchOkxExchangeInfo() {
  const response = await axios.get(
    'https://www.okx.com/api/v5/public/instruments?instType=SPOT',
  );
  return response.data.data
    .filter((symbol) => symbol.state === 'live')
    .map(adaptOkxSymbol);
}

export async function fetchGateIOExchangeInfo() {
  const response = await axios.get(
    'https://api.gateio.ws/api/v4/spot/currency_pairs',
  );
  return response.data
    .filter((symbol) => symbol.trade_status === 'tradable')
    .map(adaptGateIOSymbol);
}

export async function fetchCryptoComExchangeInfo() {
  const response = await axios.get(
    'https://api.crypto.com/exchange/v1/public/get-instruments',
  );
  return response.data.result.data
    .filter((symbol) => symbol.inst_type === 'CCY_PAIR' && symbol.tradable === true)
    .map(adaptCryptoComSymbol);
}
