import { Box } from '@mui/material';
import { Modal } from 'antd';
import { useNavigate } from 'react-router-dom';
import { MarketplaceIcon, SettingsIcon } from 'shared/icons';
import { MainButton, Text } from 'shared/ui';
import { button, iconWrapper, inner, modal, modalContent, selectButton, text, textWrapper, title, wrapper } from '../styles';

interface AddNewBotProps {
  isOpen: boolean;
  closeModal: () => void;
}

export const AddNewBot = ({
  isOpen,
  closeModal, 
}: AddNewBotProps) => {
  const navigate = useNavigate();

  const handleNavigate = (route: string) => {
    return () => {
      closeModal();
      navigate(route);
    };
  };

  return (
    <Modal
      open={isOpen}
      footer={null}
      closeIcon={false}
      style={modal}
      styles={modalContent}
      centered={true}
      closable={true}
      destroyOnClose={true}
      onCancel={closeModal}
      onOk={closeModal}
      width='100%'
    >
      <Box sx={wrapper}>
        <Box sx={button}>
          <Box sx={inner}>
            <Box sx={iconWrapper}>
              <Box
                display='flex'
                maxWidth={110}
              >
                {MarketplaceIcon}
              </Box>
              
              <Text
                type='success'
                styles={title}
              >
                Marketplace
              </Text>
            </Box>

            <Box sx={textWrapper}>
              <Text styles={text}>
                Full auto mode <br />
                Bots from community
              </Text>

              <MainButton
                type='primary'
                ghost={true}
                onClick={handleNavigate('/trading-bots/marketplace')}
                styles={selectButton}
              >
                Select
              </MainButton>
            </Box>
          </Box>
        </Box>

        <Box sx={button}>
          <Box sx={inner}>
            <Box sx={iconWrapper}>
              <Box
                display='flex'
                maxWidth={110}
              >
                {SettingsIcon}
              </Box>
              
              <Text
                type='success'
                styles={title}
              >
                Custom
              </Text>
            </Box>

            <Box sx={textWrapper}>
              <Text styles={text}>
                Connect own strategy <br />
                And monetize your bots
              </Text>

              <MainButton
                type='primary'
                ghost={true}
                styles={selectButton}
                onClick={handleNavigate('/trading-bots/create')}
              >
                Select
              </MainButton>
            </Box>
          </Box>
        </Box>
      </Box>
    </Modal>
  );
};
