import { Box } from '@mui/material';
import { Segmented } from 'antd';
import { useState } from 'react';
import { Input, LabelInput, SingleSelect, Slider, Text } from 'shared/ui';
import { segments, triggerPriceSegments } from '../consts';
import { IFields } from '../interfaces';
import { action, actions, actionTitle, inner, sliderWrapper, wrapper } from '../styles';

export const Fields = (props: IFields) => {
  const {
    inputs,
    sliderInput,
    isSkipBaseOrder,
  } = props;

  const [selectedSegment, setSelectedSegment] = useState<string>(segments[0].value);
  const [selectedTriggerPriceSegment, setSelectedTriggerPriceSegment] = useState<string>(triggerPriceSegments[0].value);

  const changeSegmentHandler = (value: string) => {
    setSelectedSegment(value);
    setSelectedTriggerPriceSegment(triggerPriceSegments[0].value);
  };

  const changeTriggerPriceSegmentHandler = (value: string) => {
    setSelectedTriggerPriceSegment(value);
  };

  const renderInputs = () => {
    const renderedInputs = inputs.map((input: IFields['inputs'][0], index: number) => {
      if (selectedTriggerPriceSegment === 'market' && index === 0) {
        return null;
      }
      
      return (
        <Box
          key={input.label}
          sx={inner}
        >
          <LabelInput
            label={input.label}
            value={input.value}
            onChange={input.onChange}
            icon={input.suffixText}
            disabled={(index === 0 && selectedSegment === segments[1].value) || (index === 1 && isSkipBaseOrder)}
          />
  
          {(index === inputs.length - 1) && (
            <Box sx={sliderWrapper}>
              <Slider
                value={+sliderInput.value}
                onChnage={sliderInput.onChange}
              />
  
              <Input
                value={sliderInput.value}
                placeholder='100%'
                maxWidth={58}
                onChange={sliderInput.onChange}
                icon='%'
              />
            </Box>
          )}
        </Box>
      );
    });

    return renderedInputs;
  };

  return (
    <Box sx={wrapper}>
      {!isSkipBaseOrder && (
        <Segmented
          value={selectedSegment}
          onChange={changeSegmentHandler}
          options={segments}
          block={true}
        />
      )}

      {(selectedSegment === segments[2].value && !isSkipBaseOrder) && (
        <Box
          display='flex'
          flexDirection='column'
          gap={2}
        >
          <Box sx={actions}>
            <Text
              type='secondary'
              styles={actionTitle}
            >
              Trigger price
            </Text>

            <Box sx={action}>
              <Input
                value=''
                onChange={() => {}}
                icon='USDT'
              />

              <SingleSelect
                maxWidth='max-content'
                select={{
                  placeholder: 'Last',
                  onChange: () => {},
                }}
                options={[{
                  label: 'Last',
                  value: '',
                }, {
                  label: 'Bid',
                  value: '',
                }, {
                  label: 'Ask',
                  value: '',
                }]}
              />
            </Box>
          </Box>

          <Segmented
            value={selectedTriggerPriceSegment}
            onChange={changeTriggerPriceSegmentHandler}
            options={triggerPriceSegments}
            block={true}
          />
        </Box>
      )}

      {renderInputs()}
    </Box>
  );
};

export default Fields;
