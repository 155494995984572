import { Box } from '@mui/material';
import { ExportGreen } from 'shared/icons';
import { MainButton, Table, Title } from 'shared/ui';
import { Card, WithDraw } from '../components';
import { columnsBtc } from '../consts';
import { button, inner, tableTitle, tableWrapper, title, titleWrapper, wrapper } from '../styles';

export const PublicBotSales = () => {
  return (
    <Box sx={wrapper}>
      <Title styles={title}>
        My public bots sales
      </Title>

      <Box sx={inner}>
        <Card
          title='Subscriptions'
          items={[{
            title: 'Total',
            value: '3',
          }, {
            title: 'Unique',
            value: '13',
          }, {
            title: 'Active',
            value: '23',
          }]}
        />

        <Card
          title='Sales'
          items={[{
            title: 'Total',
            value: '$350',
          }, {
            title: 'Withdrawn',
            value: '$350',
          }, {
            title: 'Available',
            value: '$350',
          }]}
        />

        <WithDraw />
      </Box>

      <Box sx={tableWrapper}>
        <Box sx={titleWrapper}>
          <Title styles={tableTitle}>
            Withdrawals
          </Title>

          <MainButton
            type='primary'
            ghost={true}
            icon={ExportGreen}
            styles={button}
          >
            Export
          </MainButton>
        </Box>

        <Table
          columns={columnsBtc}
          items={[{
            amount: '124.94',
            transaction: '7549...m121',
            wallet: '7549...m121',
            createDate: '03.04.2022 06:04',
            paidDate: '03.04.2022 06:04',
            status: 'paid',
          }]}
          itemsCount={10}
        />
      </Box>
    </Box>
  );
};
