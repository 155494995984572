import { SxProps, Theme } from '@mui/material';

export const background: SxProps<Theme> = {
  width: '100%',
  flex: 1,
  padding: {
    xs: '10px',
    md: '12px 10px',
  },
  display: 'flex',
  alignItems: 'center',
  maxWidth: 510,
  flexWrap: 'wrap',
  justifyContent: 'center',
  gap: '10px',
  borderRadius: 4,
  background: '#F8FBF9',
};
