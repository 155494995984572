import { SxProps, Theme } from '@mui/material';

export const actionsWrapper: SxProps<Theme> = {
  flex: 1,
  maxWidth: 95,
  display: 'flex',
  gap: 2,
  order: {
    xs: 1,
    sm: 6,
  },
};
