import { ISkyrexSubscription } from 'pages/subscriptions/subscription/interfaces';
import { ICard } from 'widgets/card/interfaces';
import { IPlans } from '../interfaces';

export const getButton = (subscription: ISkyrexSubscription, plan: IPlans['items'][0], choosePlan: (plan: IPlans['items'][0]) => void): (ICard['button'] | null) => {
  const subscriptionFee = subscription?.originalFee || 0;
  const isBasic = plan.planName === 'basic';
  const planFee = plan.originalFee;
  
  switch (true) {
  case plan.planName === subscription.planName:
    return {
      title: 'Current Plan',
      disabled: true,
    };
  case (subscriptionFee > 0 && subscriptionFee < planFee) && !isBasic:
    return {
      title: 'Upgrade Plan',
      handler: () => {
        choosePlan(plan);
      },
    };
  case (subscriptionFee <= planFee && planFee !== null):
    return {
      title: 'Subscribe',
      handler: () => {
        choosePlan(plan);
      },
    };
  default:
    return null;
  }
};
