import { SxProps, Theme } from '@mui/material';

export const links: SxProps<Theme> = {
  display: 'flex',
  flexDirection: 'column',
  gap: '8px',
  alignItems: 'center',
  borderRadius: '5px',
  backgroundColor: '#fff',
  boxShadow: '-3px -3px 3px 0px rgba(231, 231, 231, 0.25) inset, 3px 3px 3px 0px #E7E7E7 inset',
  padding: '10px 14px',
  '&>a, &>span:first-of-type': {
    fontSize: 12,
    fontWeight: 400,
    textAlign: 'center',
    cursor: 'pointer',
    '&:hover': {
      textDecoration: 'underline',
    },
  },
  '&>span': {
    fontSize: 10,
    fontWeight: 500,
    textAlign: 'center',
  },
};
