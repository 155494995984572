// @ts-nocheck

import { Box } from '@mui/material';
import { Collapse } from 'antd';
import { getAllActiveTrades } from 'entities/terminal/model/selectors/get-all-active-trades/get-all-active-trades';
import { useSelector } from 'react-redux';
import { ExpandPrimary, ExpandSecondary } from 'shared/icons';
import { Text, Title } from 'shared/ui';
import { Label } from '../components';
import { bodyCell, header, headerCell, wrapper } from '../styles';


export const History = () => {
  const activeTrades = useSelector(getAllActiveTrades);
  
  return (
    <Box sx={wrapper}>
      <Box sx={header}>
        <Box>
          <Text>Pair</Text>
        </Box>

        <Box>
          <Text>Start date</Text>
        </Box>

        <Box>
          <Text>Volume</Text>
        </Box>

        <Box>
          <Text>Progress</Text>
        </Box>

        <Box>
          <Text>Return</Text>
        </Box>

        <Box>
          <Text>Actions</Text>
        </Box>
      </Box>

      <Collapse
        defaultActiveKey={['1']}
        expandIcon={(props) => props.isActive ? ExpandPrimary : ExpandSecondary}
        collapsible='icon'
        style={{
          background: 'transparent',
          padding: 30,
          border: '1px solid #E9E9E9',
        }}
        bordered={false}
        items={[{
          key: 1,
          label: (
            <Label />
          ),
          children: (
            <Box
              display='flex'
              flexDirection='column'
              gap={3}
              paddingTop={3}
            >
              <Box
                display='flex'
                flexDirection='column' 
                gap={3}
              >
                <Box
                  display='flex'
                  alignItems='center'
                  gap={2}
                >
                  <Title level={4}>Base order</Title>
                  <Text type='success'>Buy</Text>
                </Box>

                <Box
                  sx={{
                    flex: 1,
                    width: '100%',
                    maxWidth: '100%',
                    overflowY: 'hidden',
                  }}
                >
                  <table
                    style={{
                      width: '100%',
                    }}
                  >
                    <thead>
                      <tr>
                        <th style={headerCell}>
                          <Text type='secondary'>Price</Text>    
                        </th>
                        <th style={headerCell}>
                          <Text type='secondary'>Units</Text>    
                        </th>
                        <th style={headerCell}>
                          <Text type='secondary'>Cond.price</Text>    
                        </th>
                        <th style={headerCell}>
                          <Text type='secondary'>Total</Text>    
                        </th>
                        <th style={headerCell}>
                          <Text type='secondary'>Type</Text>    
                        </th>
                        <th style={headerCell}>
                          <Text type='secondary'>Status</Text>    
                        </th>
                      </tr>
                    </thead>

                    <tbody>
                      <tr>
                        <td style={bodyCell}>
                          <Text>0.027241 USDT</Text>
                        </td>
                        <td style={bodyCell}>
                          <Text>256.3 MATIC</Text>
                        </td>
                        <td style={bodyCell}>
                          <Text>0.0034597 USDT</Text>
                        </td>
                        <td style={bodyCell}>
                          <Text>0.24 USDT</Text>
                        </td>
                        <td style={bodyCell}>
                          <Text>Cond. market</Text>
                        </td>
                        <td style={bodyCell}>
                          <Text>Completed</Text>
                        </td>
                      </tr>
                    </tbody>
                  </table>
                </Box>
              </Box>

              <Box
                display='flex'
                flexDirection='column' 
                gap={3}
              >
                <Box
                  display='flex'
                  alignItems='center'
                  gap={2}
                >
                  <Title level={4}>Stop loss</Title>
                  <Text type='success'>Sell</Text>
                </Box>

                <Box
                  sx={{
                    flex: 1,
                    width: '100%',
                    maxWidth: '100%',
                    overflowY: 'hidden',
                  }}
                >
                  <table
                    style={{
                      width: '100%',
                    }}
                  >
                    <thead>
                      <tr>
                        <th style={headerCell}>
                          <Text type='secondary'>Price</Text>    
                        </th>
                        <th style={headerCell}>
                          <Text type='secondary'>Cond.price</Text>    
                        </th>
                        <th style={headerCell}>
                          <Text type='secondary'>Percent</Text>    
                        </th>
                        <th style={headerCell}>
                          <Text type='secondary'>Type</Text>    
                        </th>
                        <th style={headerCell}>
                          <Text type='secondary'>Status</Text>    
                        </th>
                      </tr>
                    </thead>

                    <tbody>
                      <tr>
                        <td style={bodyCell}>
                          <Text>0.027241 USDT</Text>
                        </td>
                        <td style={bodyCell}>
                          <Text>256.3 MATIC</Text>
                        </td>
                        <td style={bodyCell}>
                          <Text>-9.00%</Text>
                        </td>
                        <td style={bodyCell}>
                          <Text>Cond. market</Text>
                        </td>
                        <td style={bodyCell}>
                          <Text>Completed</Text>
                        </td>
                      </tr>
                    </tbody>
                  </table>
                </Box>
              </Box>
            </Box>
          ),
        }]}
      />
    </Box>
  );
};


// import { Box } from '@mui/material';
// import { Collapse } from 'antd';
// import { getAllActiveTrades } from 'entities/terminal/model/selectors/get-all-active-trades/get-all-active-trades';
// import { useSelector } from 'react-redux';
// import { ExpandPrimary, ExpandSecondary } from 'shared/icons';
// import { Text, Title } from 'shared/ui';
// import { Label } from '../components';
// import { header, wrapper } from '../styles';


// export const History = () => {
//   const activeTrades = useSelector(getAllActiveTrades);
  
//   return (
//     <Box sx={wrapper}>
//       <Box sx={header}>
//         <Box>
//           <Text>Pair</Text>
//         </Box>

//         <Box>
//           <Text>Start date</Text>
//         </Box>

//         <Box>
//           <Text>Volume</Text>
//         </Box>

//         <Box>
//           <Text>Progress</Text>
//         </Box>

//         <Box>
//           <Text>Return</Text>
//         </Box>

//         <Box>
//           <Text>Actions</Text>
//         </Box>
//       </Box>

//       {activeTrades?.map((trade, index) => (
//         <Collapse
//           key={index} // Ensure each item has a unique key
//           expandIcon={(props) => props.isActive ? <ExpandPrimary /> : <ExpandSecondary />}
//           style={{
//             background: 'transparent',
//             padding: 30,
//             border: '1px solid #E9E9E9',
//           }}
//           bordered={false}
//           items={[
//             {
//               key: 1,
//               label: (
//                 <Label baseSymbol={trade.baseSymbol} quoteSymbol={trade.quoteSymbol}  />
//               ),
//               children: (
//                 trade.orders.map((tradeOrder) => (
//                   <Box
//                     display='flex'
//                     flexDirection='column'
//                     gap={5}
//                   >
//                     <Box
//                       display='flex'
//                       flexDirection='column' 
//                       gap={3}
//                     >
//                       <Box
//                         display='flex'
//                         alignItems='center'
//                         gap={2}
//                       >
//                         <Title level={4}>{tradeOrder.viewType}</Title>
//                         <Text type='success'>{tradeOrder.side}</Text>
//                       </Box>
    
//                       <Box
//                         display='flex'
//                         flexDirection='column' 
//                         gap={2}
//                       >
//                         <Box
//                           display='flex'
//                           alignItems='center'
//                           justifyContent='space-between'
//                         >
//                           <Text type='secondary'>Price</Text>
//                           <Text type='secondary'>Units</Text>
//                           <Text type='secondary'>Cond.price</Text>
//                           <Text type='secondary'>Total</Text>
//                           <Text type='secondary'>Type</Text>
//                           <Text type='secondary'>Status</Text>
//                         </Box>
    
//                         <Box
//                           display='flex'
//                           flexDirection='column' 
//                           gap={1.5}
//                         >
//                           <Box
//                             display='flex'
//                             alignItems='center'
//                             justifyContent='space-between'
//                           >
//                             <Text>0.027241 USDT</Text>
//                             <Text>256.3 MATIC</Text>
//                             <Text>0.0034597 USDT</Text>
//                             <Text>0.24 USDT</Text>
//                             <Text>{tradeOrder.orderType}</Text>
//                             <Text>{tradeOrder.status}</Text>
//                           </Box>
    
//                           {/* <Box
//                           display='flex'
//                           alignItems='center'
//                           justifyContent='space-between'
//                         >
//                           <Text>0.027241 USDT</Text>
//                           <Text>256.3 MATIC</Text>
//                           <Text>0.0034597 USDT</Text>
//                           <Text>0.24 USDT</Text>
//                           <Text>Cond. market</Text>
//                           <Text>Completed</Text>
//                         </Box> */}
//                         </Box>
//                       </Box>
//                     </Box>
//                   </Box>
//                 ))
//               ),
//             },
//           ]}
//         />
//       ))}
//     </Box>
//   );
// };
