
import { 
  CreateExchangeProps, 
  ExchangeProps, 
  UpdateExchangeProps, 
} from 'entities/exchange/model/types/exchange-connect-update.types';
import { CreateGate, UpdateGate } from '../views';

export const Gate = (props: ExchangeProps) => {
  const {
    isUpdate,
    isModalView,
    handleClose,
  } = props;

  const render = () => {
    if (isUpdate) {
      const {
        exchangeAccountUuid,
        currentExchangeTitle,
        closeExchangeModal,
      } = props as UpdateExchangeProps;
      
      return (
        <UpdateGate
          handleClose={handleClose}
          isModalView={isModalView} 
          exchangeAccountUuid={exchangeAccountUuid} 
          currentExchangeTitle={currentExchangeTitle}
          closeExchangeModal={closeExchangeModal}
        />
      );
    }

    const {
      returnToAllExchanges, 
    } = props as CreateExchangeProps;
    
    return (
      <CreateGate
        handleClose={handleClose}
        returnToAllExchanges={returnToAllExchanges} 
        isModalView={isModalView}
      />
    );
  };

  return render();
};
