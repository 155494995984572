import { SxProps, Theme } from '@mui/material';

export const header: SxProps<Theme> = {
  display: {
    xs: 'none',
    md: 'flex',
  },
  alignItems: 'center',
  gap: 4.25,

  '&>div': {
    '&:first-of-type': {
      flex: 1,
      maxWidth: 79,
      marginLeft: '59px',
    },
    '&:nth-child(2)': {
      flex: 1,
      maxWidth: 110,
    },
    '&:nth-child(3)': {
      flex: 1,
      maxWidth: 200,
    },
    '&:nth-child(4)': {
      flex: 1,
      maxWidth: 478,
    },
    '&:nth-child(5)': {
      flex: 1,
      maxWidth: 63,
    },
    '&:last-of-type': {
      flex: 1,
      maxWidth: 95,
    },
  },
};
