import { Box } from '@mui/material';
import { Modal } from 'antd';
import { Copy } from 'widgets';
import { ExternalLink } from 'shared/icons';
import { Link, Text, Title } from 'shared/ui';
import { additinalEntryCodeItem, closeTradeCodeItem, openTradeCodeItem } from '../consts';
import { IAlertSource } from '../interfaces';
import {
  block,
  description,
  itemTitle,
  itemWrapper,
  linkWrapper,
  modal,
  modalChildren,
  text,
  title,
  titleWrapper,
  wrapper,
} from '../styles';

export const AlertSource = (props: IAlertSource) => {
  const {
    isOpen,
    handleClose,
    sourceUuid,
    secretToken,
  } = props;

  return (
    <Modal
      open={isOpen}
      footer={null}
      style={modal}
      styles={modalChildren}
      closable={true}
      destroyOnClose={true}
      onCancel={handleClose}
      width='100%'
      centered={true}
    >
      <Box sx={wrapper}>
        <Box sx={titleWrapper}>
          <Title styles={title}>
            Alert messages
          </Title>

          <Text styles={description}>
            Control your bot with TradingView or other webhook alerts
          </Text>
        </Box>

        <Box sx={block}>
          <Text styles={itemTitle}>
            Webhook address
          </Text>

          <Copy value='https://panel.skyrexio.com/api/v1/deal/alert' />

          <Box sx={itemWrapper}>
            <Text styles={itemTitle}>
              1. Open trade
            </Text>

            <Copy value={openTradeCodeItem(sourceUuid, secretToken)} />
          </Box>

          <Box sx={itemWrapper}>
            <Text styles={itemTitle}>
              2. Make additional entry (“entry2”, “entry3”, etc.)
            </Text>

            <Copy value={additinalEntryCodeItem(sourceUuid, secretToken)} />
          </Box>

          <Box sx={itemWrapper}>
            <Text styles={itemTitle}>
              3. Close trade
            </Text>

            <Copy value={closeTradeCodeItem(sourceUuid, secretToken)} />
          </Box>
        </Box>

        <Box sx={text}>
          <Text>
            Note: trading pair BTCUSDT has “base”: “BTC” and “quote”: “USDT”.
          </Text>

          <Box>
            <Text>
              How to use:
            </Text>

            <ol>
              <li>Send Open trade message to webhook address to start a position.</li>
              <li>
                Control your positions:
                <ul>
                  <li>If Additional entries and Position close are configured as <strong>Preset</strong> then just track positions in Active trades tab at the bot’s page.</li>
                  <li>If risk management is configured as <strong>Alert</strong> then make sure you set up Additional entry and Position close alerts.</li>
                </ul>
              </li>
            </ol>
          </Box>
        </Box>
        
        <Box sx={linkWrapper}>
          <Text>
            Connect strategy to alert bot

            <Link
              type='success'
              href='#'
            >
              Full guide
            </Link>
          </Text>

          <div>
            {ExternalLink}
          </div>
        </Box>
      </Box>
    </Modal>
  );
};
