export const openTradeCodeItem = (sourceUuid?: string, secretToken?: string) => {
  return {
    'base': '{{syminfo.basecurrency}}',
    'quote': '{{syminfo.currency}}',
    'position': 'entry1',
    'price': '{{close}}',
    'sourceUuid': sourceUuid,
    'secretToken': secretToken,
  };
};

export const additinalEntryCodeItem = (sourceUuid?: string, secretToken?: string) => {
  return {
    'base': '{{syminfo.basecurrency}}',
    'quote': '{{syminfo.currency}}',
    'position': 'entry2',
    'price': '{{close}}',
    'sourceUuid': sourceUuid,
    'secretToken': secretToken,
  };
};

export const closeTradeCodeItem = (sourceUuid?: string, secretToken?: string) => {
  return {
    'base': '{{syminfo.basecurrency}}',
    'quote': '{{syminfo.currency}}',
    'position': 'close',
    'price': '{{close}}',
    'sourceUuid': sourceUuid || '',
    'secretToken': secretToken || '',
  };
};
