import { Box } from '@mui/material';
import { Modal } from 'antd';
import { useNavigate } from 'react-router-dom';
import { Copy } from 'widgets';
import { ExternalLink } from 'shared/icons';
import { MainButton, Text, Title } from 'shared/ui';
import { closeTradeCodeItem } from '../consts';
import { IAlertSource } from '../interfaces';
import {
  block,
  button,
  buttonLink,
  description,
  itemTitle,
  itemWrapper,
  linkWrapper,
  modal,
  modalChildren,
  text,
  title,
  titleWrapper,
  wrapper,
} from '../styles';

export const ManagePosition = (props: IAlertSource) => {
  const {
    isOpen,
    handleClose,
  } = props;

  const navigate = useNavigate();

  return (
    <Modal
      open={isOpen}
      footer={null}
      style={modal}
      styles={modalChildren}
      closable={true}
      destroyOnClose={true}
      onCancel={handleClose}
      width='100%'
      centered={true}
    >
      <Box sx={wrapper}>
        <Box sx={titleWrapper}>
          <Title styles={title}>
            Initiate and manage positions guide
          </Title>

          <Text styles={description}>
            Control your bot with TradingView or other webhook alerts
          </Text>
        </Box>

        <Box sx={block}>
          <Box sx={itemWrapper}>
            <Text styles={itemTitle}>
              1. “Create Bot” button will create the bot and send its page
            </Text>

            <Box
              display='flex'
              justifyContent='center'
            >
              <MainButton
                type='primary'
                styles={button}
              >
                Create Bot
              </MainButton>
            </Box>
          </Box>

          <Box sx={itemWrapper}>
            <Text styles={itemTitle}>
              2. Click “Signal source” to see alert messages for your bot
            </Text>

            <Box
              display='flex'
              justifyContent='center'
            >
              <Text
                type='success'
                styles={{
                  ...buttonLink,
                  pointerEvents: 'none',
                }}
              >
                Signal source
              </Text>
            </Box>
          </Box>

          <Box sx={itemWrapper}>
            <Text styles={itemTitle}>
              3. Copy alert message prepared for your bot
            </Text>

            <Copy
              value={closeTradeCodeItem}
              isCopy={false}
            />
          </Box>

          <Box sx={itemWrapper}>
            <Text styles={itemTitle}>
              4. Send a message to the address to perform an action
            </Text>

            <Copy
              value='https://panel.skyrexio.com/api/v1/deal/alert'
              isCopy={false}
            />
          </Box>
        </Box>

        <Box sx={text}>
          <Text>
            Note: <br />

            <Text>
              You can send messages automatically with TradingView alerts and your own scripts or manually with Postman
            </Text>
          </Text>
        </Box>
        
        <Box sx={linkWrapper}>
          <Text>
            Connect strategy to alert bot

            <Text
              type='success'
              styles={buttonLink}
              onClick={() => {
                navigate('/trading-bots/introduction');
              }}
            >
              Full guide
            </Text>
          </Text>

          <div>
            {ExternalLink}
          </div>
        </Box>
      </Box>
    </Modal>
  );
};
