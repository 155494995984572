import { Binance } from '../binance';
import { Bybit } from '../bybit';
import { Crypto } from '../crypto';
import { Gate } from '../gate';
import { IGetPlatformParams } from '../interfaces';
import { Okx } from '../okx';

export const getPlatform = (params: IGetPlatformParams) => {
  const {
    platform,
    isModalOpen,
    returnToAllExchanges,
    handleClose,
  } = params;

  switch (platform) {
  case 'binance':
    return (
      <Binance
        handleClose={handleClose}
        returnToAllExchanges={returnToAllExchanges}
        isModalView={isModalOpen}
      />
    );
  case 'okx':
    return (
      <Okx
        handleClose={handleClose}
        returnToAllExchanges={returnToAllExchanges}
        isModalView={isModalOpen}
      />
    );
  case 'bybit':
    return (
      <Bybit
        handleClose={handleClose}
        returnToAllExchanges={returnToAllExchanges}
        isModalView={isModalOpen}
      />
    );
  case 'gate':
    return (
      <Gate
        handleClose={handleClose}
        returnToAllExchanges={returnToAllExchanges}
        isModalView={isModalOpen}
      />
    );
  case 'crypto':
    return (
      <Crypto
        handleClose={handleClose}
        returnToAllExchanges={returnToAllExchanges}
        isModalView={isModalOpen}
      />
    );
  default:
    return null;
  }
};
