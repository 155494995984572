import { Box } from '@mui/material';
import { Segmented } from 'antd';
import { useState } from 'react';
import { CloseSecondaryIcon, PlusGreen } from 'shared/icons';
import { Input, MainButton, SingleSelect, Slider, Switch, Text, Title } from 'shared/ui';
import { SafetyOrders } from '../components';
import { segments } from '../consts';
import { ITakeProfit } from '../interfaces';
import { button, buttonTitle, header, headerTitle, priceInner, priceTitle, priceWrapper, sldierWrapper, wrapper } from '../styles';

export const TakeProfit = (props: ITakeProfit) => {
  const {
    inputs,
  } = props;

  const [selectedSegment, setSelectedSegment] = useState<string>(segments[0].value);
  const [volume, setVolume] = useState<string>('0');
  const [splitTarget, setSplitTarget] = useState<boolean>(false);
  
  const changeSegmentHandler = (value: string) => {
    setSelectedSegment(value);
  };

  const changeVolumeHandler = (value: any) => {
    setVolume(value);
  };

  const splitTargetHandler = () => {
    setSplitTarget(true);
  };

  const closeSplitTargetHandler = () => {
    setSplitTarget(false);
  };

  const getInput = () => {
    const [
      order,
      triggier,
    ] = inputs;
    if (selectedSegment === segments[0].value) {
      return (
        <Box sx={priceWrapper}>
          <Text
            type='secondary'
            styles={priceTitle}
          >
            {order.label}
          </Text>

          <Box sx={priceInner}>
            <Input
              value={order.value}
              onChange={order.onChange}
              icon={order.suffixText}
            />

            <Input
              value={order.value}
              placeholder='-5'
              onChange={order.onChange}
              maxWidth={58}
              icon='%'
            />
          </Box>
        </Box>
      );
    }

    return (
      <Box sx={priceWrapper}>
        <Text
          type='secondary'
          styles={priceTitle}
        >
          {triggier.label}
        </Text>

        <Box sx={priceInner}>
          <Input
            value={triggier.value}
            onChange={triggier.onChange}
            icon={triggier.suffixText}
            addonBefore={(
              <span>10%</span>
            )}
          />

          <SingleSelect
            options={[{
              label: 'Last',
              value: '',
            }, {
              label: 'Bid',
              value: '',
            }, {
              label: 'Ask',
              value: '',
            }]}
            maxWidth='max-content'
            select={{
              placeholder: 'Last',
              onChange: () => {},
            }}
          />
        </Box>
      </Box>
    );
  };
  
  return (
    <Box sx={wrapper}>
      <Box sx={header}>
        <Switch
          suffixText={(
            <Title
              level={5}
              styles={headerTitle}
            >
              Take profit
            </Title>
          )}
          size='small'
        />

        <Segmented
          options={[{
            label: 'Average',
            value: 'average',
          }, {
            label: 'Fixed',
            value: 'fixed',
          }]}
        />
      </Box>

      <Segmented
        options={segments}
        value={selectedSegment}
        onChange={changeSegmentHandler}
        block={true}
      />

      {splitTarget && (
        <SafetyOrders />
      )}

      {getInput()}

      {splitTarget && (
        <Box sx={sldierWrapper}>
          <Slider
            value={+volume}
            onChnage={changeVolumeHandler}
            defaultValue={40}
          />

          <Input
            value={volume}
            placeholder='80%'
            onChange={changeVolumeHandler}
            maxWidth={58}
            icon='%'
          />
        </Box>
      )}
      
      <Box
        display='flex'
        alignItems='center'
        gap={1}
      >
        <MainButton
          icon={PlusGreen}
          styles={button}
          onClick={splitTargetHandler}
        >
          <Title
            level={5}
            styles={buttonTitle}
          >
            {splitTarget ? 'Add target' : 'Split target'} 
          </Title>
        </MainButton>

        {splitTarget && (
          <MainButton onClick={closeSplitTargetHandler}>
            {CloseSecondaryIcon}
          </MainButton>
        )}
      </Box>
    </Box>
  );
};
