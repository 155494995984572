import { Box } from '@mui/material';
import { Segmented } from 'antd';
import { useState } from 'react';
import { Input, SingleSelect, Switch, Text, Title } from 'shared/ui';
import { segments } from '../consts';
import { IStopLoss } from '../interfaces';
import { header, headerTitle, priceInner, priceTitle, priceWrapper, wrapper } from '../styles';

export const StopLoss = (props: IStopLoss) => {
  const {
    inputs,
  } = props;

  const [selectedSegment, setSelectedSegment] = useState<string>(segments[0].value);
  
  const changeSegmentHandler = (value: string) => {
    setSelectedSegment(value);
  };

  const getInputs = () => {
    const renderedInputs = inputs.map((input: IStopLoss['inputs'][0], index: number) => {
      if (index === 0) {
        return (
          <Box
            key={input.label}
            sx={priceWrapper}
          >
            <Text
              type='secondary'
              styles={priceTitle}
            >
              {input.label}
            </Text>
    
            <Box sx={priceInner}>
              <Input
                value={input.value}
                onChange={input.onChange}
                icon={input.suffixText}
                addonBefore='10%'
              />
    
              <SingleSelect
                options={[{
                  label: 'Last',
                  value: '',
                }, {
                  label: 'Bid',
                  value: '',
                }, {
                  label: 'Ask',
                  value: '',
                }]}
                maxWidth='max-content'
                select={{
                  placeholder: 'Last',
                  onChange: () => {},
                }}
              />
            </Box>
          </Box>
        );
      }

      if (index === 1 && selectedSegment !== segments[1].value) {
        return (
          <Box
            key={input.label}
            sx={priceWrapper}
          >
            <Text
              type='secondary'
              styles={priceTitle}
            >
              {input.label}
            </Text>
  
            <Box sx={priceInner}>
              <Input
                value={input.value}
                onChange={input.onChange}
                icon={input.suffixText}
              />
  
              <Input
                value={input.value}
                placeholder='100'
                onChange={input.onChange}
                maxWidth={58}
                icon='%'
              />
            </Box>
          </Box>
        );
      }

      return null;
    });

    return renderedInputs;
  };

  return (
    <Box sx={wrapper}>
      <Box sx={header}>
        <Switch
          suffixText={(
            <Title
              level={5}
              styles={headerTitle}
            >
              Stop loss
            </Title>
          )}
          size='small'
        />

        <Segmented
          options={[{
            label: 'Average',
            value: 'average',
          }, {
            label: 'Fixed',
            value: 'fixed',
          }]}
        />
      </Box>

      <Segmented
        value={selectedSegment}
        onChange={changeSegmentHandler}
        options={segments}
        block={true}
      />

      {getInputs()}
    </Box>
  );
};
