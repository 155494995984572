import { Box } from '@mui/material';
import { Divider } from 'antd';
import { MainContext } from 'pages/trading-bots/configurator/context';
import { useContext, useEffect, useMemo } from 'react';
import { Text, Title } from 'shared/ui';
import { content, header, row, table, text, title, wrapper } from '../styles';

export const PositionClose = (props: any) => {
  const {
    fullPosition,
    baseOrder,
    setIsValidationError,
  } = props;

  const {
    initialDataValue: {
      main,
      closeOrders,
      closeOrders: {
        takeProfit,
        stopLoss,
      },
    },
  } = useContext(MainContext);

  const getPriceChange = (postion: number) => {
    if (closeOrders.type === 'alert') {
      return 0;
    }

    const orderPriceChangeScale = +takeProfit.orders === 1 ? 1 : +takeProfit.orderPriceChangeScale;
    if (orderPriceChangeScale === 1) {
      return takeProfit.priceChange * postion;
    }

    return +takeProfit.priceChange * ((1 - (orderPriceChangeScale ** postion)) / (1 - orderPriceChangeScale));
  };

  const getVolume = (postion: number) => {
    if (closeOrders.type === 'alert') {
      return 100;
    }

    const orderSizeScale = +takeProfit.orders === 1 ? 1 : +takeProfit.orderSizeScale;
    if (orderSizeScale === 1) {
      return (100 / +takeProfit.orders);
    }

    return ((100 * (1 - orderSizeScale) / (1 - (orderSizeScale ** +takeProfit.orders))) * orderSizeScale ** (postion - 1));
  };

  const items = useMemo(() => {
    const formattedItems = new Array(closeOrders.type === 'alert' ? 1 : +takeProfit.orders).fill('').map((_, index) => {
      const position = ++index;

      const priceChange = getPriceChange(position);
      const volume = getVolume(position);

      return {
        position,
        priceChange,
        volume,
      };
    });

    return formattedItems;
  }, [closeOrders]);

  const isError = closeOrders.type === 'alert' ? false : main.filter >= (fullPosition * (1 + (+stopLoss.priceChange / 100)));
  const isTakeProfitErrorHnadler = () => {
    if (!takeProfit.isOpened || closeOrders.type === 'alert') {
      return;
    }

    for (const item of items) {
      const value = ((item.volume / 100) * (1 + (+item.priceChange / 100)) * baseOrder);
      if (value < main.filter) {
        return true;
      }

      continue;
    }

    return false;
  };

  const isTakeProfitError = isTakeProfitErrorHnadler();

  useEffect(() => {
    setIsValidationError(isError || isTakeProfitError);
  }, [isError, isTakeProfitError]);

  return (
    <Box sx={wrapper}>
      <Title styles={title}>
        Close orders
      </Title>

      {takeProfit.isOpened && (
        <Box
          display='flex'
          flexDirection='column'
          gap='32px'
        >
          <Box
            display='flex'
            flexDirection='column'
          >
            <Text
              styles={text}
              type={isTakeProfitError ? 'danger' : undefined}
            >
              Take profit orders
            </Text>

            {isTakeProfitError && (
              <Text
                styles={text}
                type={isTakeProfitError ? 'danger' : undefined}
              >
                Order size is below the minimum
              </Text>
            )}
          </Box>

          <Box sx={table}>
            <Box sx={header}>
              <Text>
                №
              </Text>

              <Text>
                Price change
              </Text>

              <Text>
                Volume
              </Text>
            </Box>

            {items.map((item) => (
              <Box
                key={item.position}
                sx={content}
              >
                <Text>
                  {item.position}
                </Text>

                <Text>
                  {item.priceChange ? `${(item.priceChange).toFixed(2)}%` : 'Alert'}
                </Text>

                <Text>
                  {item.volume.toFixed(2)}%
                </Text>
              </Box>
            ))}
          </Box>
        </Box>
      )}

      <Divider
        style={{
          margin: 0,
        }}
      />

      {stopLoss.isOpened && (
        <Box>
          <Box sx={row}>
            <Text
              styles={text}
              type={isError ? 'danger' : undefined}
            >
              Stop loss
            </Text>

            <Text
              type={isError ? 'danger' : 'secondary'}
              styles={text}
            >
              {closeOrders.type === 'preset' ? `${stopLoss.priceChange > 0 ? 0 : stopLoss.priceChange}%` : 'Alert'}
            </Text>
          </Box>

          {isError && (
            <Text
              styles={text}
              type={isError ? 'danger' : undefined}
            >
              Order size is below the minimum
            </Text>
          )}
        </Box>
      )}
    </Box>
  );
};
