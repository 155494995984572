import { Box } from '@mui/material';
import { Segmented } from 'antd';
import { useState } from 'react';
import { Switch, Text } from 'shared/ui';
import { segments } from '../consts';
import { IWrapper } from '../interfaces';
import { inner, text, wrapper } from '../styles';

export const Wrapper = (props: IWrapper) => {
  const {
    children,
  } = props;

  const [selectedSegment, setSelectedSegment] = useState<string>(segments[0].value);
  const [isSkipBaseOrder, setIsSkipBaseOrder] = useState<boolean>(false);

  const changeSegmentHandler = (value: string) => {
    setSelectedSegment(value);
  };

  const skipBaseOrderHandler = (value: boolean) => {
    setIsSkipBaseOrder(value);
  };

  return (
    <Box sx={wrapper}>
      <Box sx={inner}>
        <Segmented
          value={selectedSegment}
          onChange={changeSegmentHandler}
          options={segments}
          block={true}
        />
        
        <Switch
          value={isSkipBaseOrder}
          onChange={skipBaseOrderHandler}
          suffixText={(
            <Text
              type='secondary'
              styles={text}
            >
              Skip base order (use existing funds)
            </Text>
          )}
          size='small'
        />
      </Box>

      {children(selectedSegment, isSkipBaseOrder)}
    </Box>
  );
};

export default Wrapper;
