import { Box } from '@mui/material';
import { Text, Title } from 'shared/ui';
import { ICard } from '../interfaces';
import {
  line,
  list,
  listItem,
  listItemTitle,
  listItemValue,
  title as titleStyles,
  wrapper,
} from '../styles';

export const Card = (props: ICard) => {
  const {
    title,
    items,
  } = props;

  return (
    <Box sx={wrapper}>
      <Title
        level={4}
        styles={titleStyles}
      >
        {title}
      </Title>

      <Box sx={list}>
        {items.map((item: ICard['items'][0]) => (
          <Box
            key={item.title}
            sx={listItem}
          >
            <Text
              type='secondary'
              styles={listItemTitle}
            >
              {item.title}
            </Text>

            <Box sx={line}></Box>
            
            <Text
              type='secondary'
              styles={listItemValue}
            >
              {item.value}
            </Text>
          </Box>
        ))}
      </Box>
    </Box>
  );
};
