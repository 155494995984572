import { Box } from '@mui/material';
import { setExchangeData } from 'entities/exchange/helpers/set-exchanges-data';
import {
  exchangeActions,
} from 'entities/exchange/model/slices/exchange-slice';
import { UpdateExchangeProps } from 'entities/exchange/model/types/exchange-connect-update.types';
import {
  updateExchange,
} from 'features/update-exchange/api/update-exchange';
import { useState } from 'react';
import { useDispatch } from 'react-redux';
import { GateLogo } from 'widgets/choose-exchange/components/tabs/icons';
import { ActionBlock, Form, Info } from 'widgets/exchanges/components';
import { getWrapperStyles, logo, platformLogo } from 'widgets/exchanges/styles';
import {
  getSkyrexUuid,
} from 'shared/helpers/storage-helper';
import { Verify } from 'shared/icons';
import { Loader, Title } from 'shared/ui';
import {
  ConnectedExchangeAccountStatus as ExchangeUpdatedModalStatus,
} from 'shared/ui/modals';

export const UpdateGate = (props: UpdateExchangeProps) => {
  const {
    isModalView,
    exchangeAccountUuid,
    currentExchangeTitle,
    closeExchangeModal,
  } = props;

  const skyrexUserUuid = getSkyrexUuid();
  const [isApiKeysConnection, setIsApiKeysConnection] = useState<boolean>(false);
  const [exchangeTitle, setExchangeTitle] = useState<string>(currentExchangeTitle);
  const [apiKey, setApiKey] = useState<string>('');
  const [apiSecret, setApiSecret] = useState<string>('');
  const [modalOpen, setIsModalOpen] = useState<boolean>(false);
  const [isLoading, setIsLoading] = useState<boolean>(false);
  const [error, setError] = useState<boolean>(false);
  const dispatch = useDispatch();

  const onCloseErrorModal = () => {
    setIsModalOpen(false);
  };

  const onCloseSuccessModal = () => {
    setIsModalOpen(false);
    closeExchangeModal && closeExchangeModal();
  };

  const updateGateWithApiKeys = async () => {
    setIsLoading(true);
    setError(false);
    
    try {
      const dataForUpdateOauth = {
        exchangeAccountUuid: exchangeAccountUuid,
        accountName: currentExchangeTitle,
        exchangeCode: 'gateio',
        apiKey,
        apiSecret,
      };

      const gateUpdateResponse = await updateExchange(dataForUpdateOauth);

      if (gateUpdateResponse.success) {
        const exchangeAccounts = await setExchangeData(skyrexUserUuid!);
        dispatch(exchangeActions.setAllExchanges(exchangeAccounts));
        return;
      }

      setError(true);
    } catch (error) {
      setError(true);
    } finally {
      setIsLoading(false);
      setIsModalOpen(true);
    }
  };

  const handleChangeTitle = (value: string) => {
    setExchangeTitle(value);
  };

  const handleChangeApiKey = (value: string) => {
    setApiKey(value);
  };

  const handleChangeApiSecret = (value: string) => {
    setApiSecret(value);
  };

  const switchForm = () => {
    setIsApiKeysConnection((prev) => !prev);
  };

  const getContent = () => {
    if (isApiKeysConnection) {
      return (
        <Form
          type='binance'
          fields={[{
            disabled: true,
            label: 'Enter any name',
            value: exchangeTitle,
            placeholder: 'Name',
            onChange: handleChangeTitle,
          }, {
            label: 'Paste your API key',
            value: apiKey,
            placeholder: 'Key',
            onChange: handleChangeApiKey,
          }, {
            label: 'Paste your API secret',
            value: apiSecret,
            placeholder: 'Secret',
            mask: true,
            onChange: handleChangeApiSecret,
          }]}
          actionBlock={(
            <ActionBlock
              button={{
                label: 'Update Gate.io',
                action: updateGateWithApiKeys,
              }}
              link={{
                label: (
                  <Box
                    display='flex'
                    alignItems='center'
                    gap={1}
                  >
                    Update with OAuth
                    {Verify}
                  </Box>
                ),
                action: switchForm,
              }}
            />
          )}
        />
      );
    }

    return (
      <Info
        steps={[
          'Click on Connect Gate.io button',
          'You will be redirected to Gate.io',
          'Confirm your connection to Skyrexio',
        ]}
        actionBlock={(
          <ActionBlock
            button={{
              label: 'Update Gate.io',
              action: () => {},
              disabled: true,
            }}
            link={{
              label: 'Update with API keys',
              action: switchForm,
            }}
          />
        )}
      />
    );
  };

  return (
    <>
      <ExchangeUpdatedModalStatus
        isOpen={modalOpen}
        onClose={error ? onCloseErrorModal : onCloseSuccessModal}
        isError={error ? true : false}
        buttonStyles={error ? {
          border: '1px solid red',
          color: 'red',
          height: 'auto',
          padding: '10px 40px',
          maxWidth: 'max-content',
          alignSelf: 'center',
        } : null}
        title={
          error
            ? 'Failed to update'
            : 'Exchange account updated successfully'
        }
        description={
          error
            ? 'Check permissions in API settings and try again'
            : 'Now you are in one click to start trading bot or create manual trade using your account'
        }
        action={error 
          ? onCloseErrorModal 
          : onCloseSuccessModal
        }
      />

      <Box position='relative'>
        {isLoading && (
          <Loader isContentOverflow={true} />
        )}

        <Box sx={getWrapperStyles(!!isModalView)}>
          <Box sx={logo}>
            <Title>Update</Title>

            <Box sx={platformLogo}>
              {GateLogo}
            </Box>

            <Title>exchange</Title>
          </Box>

          {getContent()}
        </Box>
      </Box>
    </>
  );
};
