import { SxProps, Theme } from '@mui/material';

export const wrapper: SxProps<Theme> = {
  display: 'flex',
  flexDirection: 'column',
  justifyContent: 'center',
  gap: '17px',
  backgroundColor: '#fff',
  borderRadius: '16px',
  padding: {
    xs: '24px 10px',
    md: '35px 30px',
  },
  flex: 1,
};
