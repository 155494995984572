import { Box } from '@mui/material';
import { Form, Modal } from 'antd';
import dayjs from 'dayjs';
import { ExchangeAccount } from 'entities/exchange/model/types/exchange-account';
import { getBinanceSymbols } from 'entities/terminal/model/selectors/get-binance-symbols.ts/get-binance-symbols';
import { getBybitSymbols } from 'entities/terminal/model/selectors/get-bybit-symbols/get-bybit-symbols';
import { getOkxSymbols } from 'entities/terminal/model/selectors/get-okx-symbols/get-okx-symbols';
import { terminalActions } from 'entities/terminal/model/slices/terminal-slice';
import { Bot } from 'pages/trading-bots/marketplace/types/bots.types';
import { ActiveDeal, fetchPublicBotDeals } from 'pages/trading-bots/my-bot/helpers/fetch-all-deals';
import { getClosePrice } from 'pages/trading-bots/my-bot/helpers/get-close-price';
import { getEntryPrice } from 'pages/trading-bots/my-bot/helpers/get-entry-price';
import { getReturnPercent } from 'pages/trading-bots/my-bot/helpers/get-return-percent';
import { getReturnValue } from 'pages/trading-bots/my-bot/helpers/get-return-value';
import { getTotal } from 'pages/trading-bots/my-bot/helpers/get-total-price';
import { getExchangeSymbols } from 'pages/trading-terminal-page/trading-chart/helpers/get-symbols';
import { useEffect, useMemo, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { useNavigate } from 'react-router-dom';
import { TradingStatistic } from 'widgets';
import { getExchangesIds } from 'widgets/exchanges/api/get-exchanges';
import { roundValue } from 'shared/helpers';
import { getCapitalizedExchangeTitle } from 'shared/helpers/get-capitalized-exchange-title';
import { getSkyrexUuid } from 'shared/helpers/storage-helper';
import { useSearch } from 'shared/hooks';
import { PlusWhite, SecondaryInfo } from 'shared/icons';
import {
  LabelInput,
  Loader,
  MainButton,
  RangePicker,
  Search,
  SingleSelect,
  Table,
  Title,
  Tooltip,
} from 'shared/ui';
import { createNewCopyBot } from '../api/create-copy-bot';
import { createCopyBotSubscription } from '../api/create-copy-bot-subscription';
import { botNameValidator, connectedAccoutValidator, tradingBalanceValidator } from '../consts';
import { columnsCopyBot } from '../consts/columns-copy-bot';
import { action, button, buttonWrapper, formItem, inner, modal, modalChildren, tooltip, topWrapper, wrapper } from '../styles';

interface CopyBotProps {
  isOpen: boolean;
  closeModal: () => void;
  isSignalSource?: boolean;
  bot: Bot;
}

export const CopyBot = ({
  isOpen,
  closeModal, 
  isSignalSource,
  bot,
}: CopyBotProps) => {
  const skyrexUserUuid = getSkyrexUuid();
  
  const [form] = Form.useForm();
  
  const dispatch = useDispatch();
  const navigate = useNavigate();
  
  const [botName, setBotName] = useState<string>('');
  const [botTrades, setBotTrades] = useState<ActiveDeal[]>([]);
  const [exchangeAccs, setExchangeAccs] = useState([]);
  const [tradingBalance, setTradingBalance] = useState(bot.minInvestment);
  const [selectedExchange, setSelectedExchange] = useState<string>('');
  const [selectedPeriodOption, setSelectedPeriodOption] = useState<string>('summary');
  const [chartData, setChartData] = useState<[string, number][]>([]);
  const [dates, setDates] = useState<[dayjs.Dayjs | null, dayjs.Dayjs | null] | null>([null, null]);
  const [isTradingHistoryLoading, setIsTradingHistoryLoading] = useState<boolean>(true);

  const {
    search,
    changeSearchValue,
    filterItemsBySearch,
  } = useSearch([
    'baseSymbol',
    'quoteSymbol',
  ]);
  
  const handleSetBotName = (value: string) => {
    if (value.length > 20) {
      setBotName(value.slice(0, 20));
      return;
    }
    
    setBotName(value);
    form.setFieldValue('botName', value);
  };
  
  const handleSetTradingBalance = (value: string) => {
    const numericValue = value.replace(/[^\d.]/g, '').replace(/(\..*)\./g, '$1');
    if (/^\d/.test(numericValue)) {
      setTradingBalance(numericValue);
      form.setFieldValue('tradingBalance', numericValue);
    } else if (numericValue === '') {
      setTradingBalance('');
      form.setFieldValue('tradingBalance', '');
    }
  };
  
  const handleSelectExchange = (selected: string) => {
    setSelectedExchange(selected);
    form.setFieldsValue({
      ...form.getFieldsValue(),
      selectedExchange: selected,
    });
  };
  
  const getExchangeAccounts = async () => {
    const exchangeDataResponse = await getExchangesIds(skyrexUserUuid ?? '');
    
    const {
      accounts,
    } = exchangeDataResponse.data;
    
    const exchangeAccountsMap = accounts
      .filter((account: ExchangeAccount) => account.exchangeCode === bot.exchangeCode)
      .map((account: ExchangeAccount) => ({
        label: account.accountName,
        value: account.exchangeAccountUuid,
        exchangeCode: account.exchangeCode,
      }));

    setExchangeAccs(exchangeAccountsMap);
  };
  
  const fetchExchangeSymbols = async () => {
    try {
      const binanceSymbols = await getExchangeSymbols('binance');
      const okxSymbols = await getExchangeSymbols('okx');
      const bybitSymbols = await getExchangeSymbols('bybit');
      dispatch(terminalActions.setBinanceSymbols(binanceSymbols));
      dispatch(terminalActions.setOkxSymbols(okxSymbols));
      dispatch(terminalActions.setBybitSymbols(bybitSymbols));
    } catch (error) {
      dispatch(terminalActions.setBinanceSymbols([]));
      dispatch(terminalActions.setOkxSymbols([]));
      dispatch(terminalActions.setBybitSymbols([]));
    }
  };
  
  useEffect(() => {
    fetchExchangeSymbols();
  }, []);
  
  useEffect(() => {
    getExchangeAccounts();
  }, []);
  
  const handleSetDates = (date: [dayjs.Dayjs | null, dayjs.Dayjs | null] | null) => {
    setDates(date);
  };
  
  const getBotDealsData = async (botUuid: string) => {
    const botDeals = await fetchPublicBotDeals(botUuid);
    setBotTrades(botDeals);
    setIsTradingHistoryLoading(false);
  }; 
  
  const binanceSymbols = useSelector(getBinanceSymbols);
  const okxSymbols = useSelector(getOkxSymbols);
  const bybitSymbols = useSelector(getBybitSymbols);
  
  const createCopyBot = async () => {
    try {
      const responseFromCreateCopyBot = await createNewCopyBot(
        {
          tradingAmount: tradingBalance,
          botUuid: bot.botUuid,
          exchangeCode: bot.exchangeCode,
          exchangeAccountUuid: selectedExchange,
          botName: botName, 
        },
      );
      
      if (!responseFromCreateCopyBot.success) {
        return;
      }

      const responseFromCreateBotSuccessSubscription = await createCopyBotSubscription({
        sourceUuid: responseFromCreateCopyBot.data.fields.sourceUuid,
        botUuid: responseFromCreateCopyBot.data.fields.botUuid,
      });
      
      if (!responseFromCreateBotSuccessSubscription.success) {
        return;
      }
      
      const createdCopyBotUuid = responseFromCreateCopyBot.data.fields.botUuid;
      navigate(`/trading-bots/my-bots/bot/${createdCopyBotUuid}`);
      return;
    } catch (error) {
      console.log(error);
    }
  };
  
  const handleCreateCopyBot = () => {
    form
      .validateFields()
      .then(() => {
        createCopyBot(); 
      })
      .catch((errorInfo) => {
        console.error('Validation Failed:', errorInfo);
      });
  };
  
  useEffect(() => {
    getBotDealsData(bot.botUuid);
  }, []);
  
  const handleSetPeriodOption = (value: string) => {
    setSelectedPeriodOption(value);
  };
  
  useEffect(() => {
    let chartData: [string, number][] = [];
    
    switch (selectedPeriodOption) {
    case 'day':
      chartData = bot.statistics.dayChartData.map((element) => [
        element.date,
        (element.return / bot.statistics.totalPnl) * bot.statistics.roi,
      ]);
      break;
  
    case 'pair':
      chartData = bot.statistics.pairChartData.map((element) => [
        element.pair,
        (element.return / bot.statistics.totalPnl) * bot.statistics.roi,
      ]);
      break;
  
    case 'summary':
    default:
      chartData = bot.statistics.sumChartData.map((element) => [
        element.date,
        (element.return / bot.statistics.totalPnl) * bot.statistics.roi,
      ]);
      break;
    }
    
    if (dates && dates[0] && dates[1]) {
      const startDate = dayjs(dates[0]).startOf('day');
      const endDate = dayjs(dates[1]).endOf('day');

      chartData = chartData.filter(([dateString]) => {
        const date = dayjs(dateString);
        return date.isAfter(startDate, 'day') && date.isBefore(endDate, 'day');
      });
    }
    
    setChartData(chartData);
  }, [selectedPeriodOption, dates]);
  
  const TradingHistoryTable = useMemo(() => (
    <Table
      title='Trading history'
      columns={columnsCopyBot}
      items={botTrades ? botTrades.filter(filterItemsBySearch).map((trade) => ({
        pair: {
          first: trade.baseSymbol, second: trade.quoteSymbol, 
        },
        closeDate: trade.finishedDate,
        create: trade.createdDate,
        status: trade.status,
        total: getTotal(trade, bot?.exchangeCode ?? 'binance', binanceSymbols, bybitSymbols, okxSymbols),
        entryPrice: getEntryPrice(trade, bot?.exchangeCode ?? 'binance', binanceSymbols, bybitSymbols, okxSymbols),
        closePrice: getClosePrice(trade, bot?.exchangeCode ?? 'binance', binanceSymbols, bybitSymbols, okxSymbols),
        return: getReturnValue(trade, binanceSymbols),
        returnPercent: getReturnPercent(trade),
      })) : []}
      itemsCount={5}
      action={(
        <Search
          value={search}
          maxWidth={480}
          onChange={changeSearchValue}
          placeholder='Search by pair'
        />
      )}
    />
  ), [botTrades]);
  
  return (
    <Modal
      open={isOpen}
      footer={null}
      style={modal}
      styles={modalChildren}
      closable={true}
      destroyOnClose={true}
      onCancel={closeModal}
      onOk={closeModal}
      width='100%'
      centered={true}
    >
      <Box sx={wrapper}>
        {!isSignalSource && (
          <Form form={form} layout='vertical'>
            <Box sx={topWrapper}>
              <Form.Item
                name='botName'
                style={formItem}
                rules={botNameValidator}
              >
                <LabelInput
                  value={botName}
                  placeholder='Set any name'
                  onChange={handleSetBotName}
                />
              </Form.Item>

              <Form.Item
                name='selectedExchange'
                style={formItem}
                rules={connectedAccoutValidator}
              >
                <SingleSelect
                  options={exchangeAccs}
                  select={{
                    value: selectedExchange || undefined,
                    placeholder: 'Choose from connected accounts',
                    onChange: handleSelectExchange,
                  }}
                />
              </Form.Item>
              
              <Form.Item
                name='tradingBalance'
                style={formItem}
                rules={tradingBalanceValidator(+bot.minInvestment)}
              >
                <LabelInput
                  value={tradingBalance}
                  placeholder={`Set trading amount. Minimum ${bot.minInvestment ?? 0}`}
                  onChange={handleSetTradingBalance}
                  suffix={(
                    <Tooltip title='This amount will be managed by bot. Make sure you have that amount on selected exchange account'>
                      {SecondaryInfo}
                    </Tooltip>
                  )}
                />
              </Form.Item>

              <Box sx={buttonWrapper}>
                <MainButton
                  type='primary'
                  icon={PlusWhite}
                  iconPosition='start'
                  styles={button}
                  onClick={handleCreateCopyBot}
                >
                  Copy Bot
                </MainButton>
              </Box>
            </Box>
          </Form>
        )}

        <Box sx={inner}>
          <Title>
            Details
          </Title>

          <TradingStatistic
            selectedQuote={bot.quote}
            disableTitle={true}
            listItems={[{
              title: 'Profit sharing fee',
              tooltip: (
                <div style={tooltip}>
                  Total lifetime profit generated from all closed deals.
                  Please note: this statistic is affected by any filters you have active
                </div>
              ),
              value: bot.copyCommissionPercent,
            }, {
              title: 'Exchange',
              tooltip: (
                <div style={tooltip}>
                  Total lifetime profit generated from all closed deals.
                  Please note: this statistic is affected by any filters you have active
                </div>
              ),
              value: getCapitalizedExchangeTitle(bot.exchangeCode),
            }, {
              title: 'Min. Trading amount',
              tooltip: (
                <div style={tooltip}>
                  Current profit or loss total for any active deals your have open.
                  Please note, this statistic is affected by any filters you have active
                </div>
              ),
              value: bot.minInvestment ?? 0,
            }, {
              title: 'Market',
              tooltip: (
                <div style={tooltip}>
                  Profit taken from closed deals (resets at midnight UTC+0 each day).
                  Please note: this statistic is affected by any filters you have active
                </div>
              ),
              value: bot.quote,
            }, {
              title: 'ROI',
              tooltip: (
                <div style={tooltip}>
                  Profit taken from closed deals (resets at midnight UTC+0 each day).
                  Please note: this statistic is affected by any filters you have active
                </div>
              ),
              value: roundValue(bot.statistics.roi, 2),
              type: 'roi',
            }, {
              title: 'Max drawdown',
              tooltip: (
                <div style={tooltip}>
                  Currently open deals. Please note: this statistic is affected by any filters you have active
                </div>
              ),
              value: roundValue(bot.statistics.maxDrawdown, 2),
              type: 'max-drawdown',
            }, {
              title: 'Total trades',
              tooltip: (
                <div style={tooltip}>
                  Total amount of funds locked in active deals or on limit orders in the exchange orderbooks for active deals.
                  Please note: this statistic is affected by any filters you have active
                </div>
              ),
              value: bot.statistics.totalTrades,
            }]}
            chartData={chartData}
            isCopyBot={true}
            selectedSegment={selectedPeriodOption}
            setSelectedSegment={handleSetPeriodOption}
            chartAction={(
              <Box sx={action}>
                <RangePicker
                  value={dates!}
                  handleSetNewDate={handleSetDates}
                />
              </Box>
            )}
          />

          <Box position='relative'>
            {isTradingHistoryLoading && (
              <Loader isContentOverflow={true} />
            )}
            
            {TradingHistoryTable}
          </Box>
        </Box>
      </Box>
    </Modal>
  );
};
